import { FC, useRef } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { Box } from '@mui/material';

const Blank: FC = () => {
  const outletParent = useRef(null);

  const outletContext = useOutletContext();

  return (
    <Box component="section" ref={outletParent}>
      <Outlet context={outletContext ? outletContext : { parent: outletParent.current }} />
    </Box>
  );
};

export default Blank;
