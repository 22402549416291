import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';
import { noop } from 'lodash';
import { InputAdornment, TextField, useTheme } from '@mui/material';

import SearchIcon from 'components/common/icons/Search';
import useDidMount from 'hooks/useDidMount';

interface SearchFieldProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const SearchField: FC<SearchFieldProps> = ({ defaultValue, onChange = noop }) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>(defaultValue || '');

  const handleSearchChange = useCallback(
    (newValue: string): void => {
      if (!newValue) {
        onChange('');
      } else if (newValue.length >= 3) {
        onChange(newValue);
      }
    },
    [onChange],
  );

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setValue(event.target.value);
  }, []);

  useDidMount(() => {
    const timeOutId = setTimeout(() => handleSearchChange(value), 700);

    return () => {
      clearTimeout(timeOutId);
    };
  }, [value]);

  useEffect(() => {
    if (!defaultValue) {
      setValue((prev) => (prev === defaultValue ? prev : ''));
    }
  }, [defaultValue]);
  return (
    <TextField
      value={value}
      placeholder="Search"
      onChange={handleInputChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
      sx={{
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          width: 320,
        },
      }}
    />
  );
};

export default SearchField;
