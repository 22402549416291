import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DATE_FORMAT } from 'configs';

function mirror<T>(anyThing: T): T {
  return anyThing;
}

interface FormDatePickerProps<TFieldValues extends FieldValues = FieldValues> {
  name: Path<TFieldValues>;
  label?: string;
  control: Control<TFieldValues>;
  required?: boolean;
  disabled?: boolean;
}

function FormDatePicker<TFieldValues extends FieldValues = FieldValues>({
  control,
  name,
  label,
  required,
  disabled,
}: FormDatePickerProps<TFieldValues>): JSX.Element | null {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  if (!control || !name) {
    return null;
  }

  return (
    <Box width="100%">
      {label && (
        <Typography
          mb={0.5}
          fontSize={14}
          fontWeight={500}
          lineHeight="20px"
          {...(disabled && { color: 'custom.grayscale.scale.50' })}
        >
          {label} {required && '*'}
        </Typography>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker<string>
          disabled={disabled}
          format={DATE_FORMAT}
          value={value}
          onChange={onChange}
          dayOfWeekFormatter={mirror}
        />
      </LocalizationProvider>
      {!!error && (
        <Typography variant="caption" color="error">
          {error.message}
        </Typography>
      )}
    </Box>
  );
}

export default FormDatePicker;
