import { ApiResponseType, ChangePasswordFormType } from 'types';
import fetcher from 'utils/fetcher';

import { PUT_AGENT_PASSWORD } from '../paths/constants';

export const changePassword = (
  model: Partial<ChangePasswordFormType>,
): Promise<ApiResponseType<ChangePasswordFormType>> =>
  fetcher<ChangePasswordFormType, Partial<ChangePasswordFormType>>({
    method: 'PUT',
    url: PUT_AGENT_PASSWORD,
    body: model,
  });
