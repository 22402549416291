import { AgentPermissionsEnum } from 'enums/accessTemplates';

import SettingsIcon from 'components/common/icons/bulk/Settings';
import HierarchySquareIcon from 'components/common/icons/bulk/HierarchySquare';
import PresentationChartIcon from 'components/common/icons/bulk/PresentationChart';
import UsersIcon from 'components/common/icons/bulk/Users';
import AgentsIcon from 'components/common/icons/bulk/Agents';
import ReportsIcon from 'components/common/icons/bulk/Reports';

import Tree from '../components/common/icons/Tree';

export type NavigationRouteType = {
  name: string;
  path?: string;
  icon?: JSX.Element;
  items?: NavigationRouteType[];
  hide?: boolean;
  permissions?: AgentPermissionsEnum[];
};

export const navigationRoutes: NavigationRouteType[] = [
  {
    name: 'Partner',
    icon: <HierarchySquareIcon />,
    path: '/partners',
    permissions: [AgentPermissionsEnum.SUPER_ADMIN],
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    icon: <PresentationChartIcon />,
  },
  {
    name: 'Agents',
    icon: <AgentsIcon />,
    path: '/agents/own',
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.AGENT_OWN_READ,
      AgentPermissionsEnum.AGENT_OWN_WRITE,
    ],
  },
  {
    name: 'Agents Tree',
    path: '/agents-tree',
    icon: <Tree />,
    permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN, AgentPermissionsEnum.AGENT_SUB_READ],
  },
  {
    name: 'Players',
    icon: <UsersIcon />,
    path: '/players',
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.PLAYER_OWN_READ,
      AgentPermissionsEnum.PLAYER_OWN_WRITE,
      AgentPermissionsEnum.PLAYER_SUB_READ,
      AgentPermissionsEnum.PLAYER_SUB_WRITE,
    ],
  },
  {
    name: 'Reports',
    icon: <ReportsIcon />,
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.AGENT_OWN_READ,
      AgentPermissionsEnum.AGENT_SUB_READ,
      AgentPermissionsEnum.PLAYER_OWN_READ,
      AgentPermissionsEnum.PLAYER_SUB_READ,
    ],
    items: [
      {
        name: 'By Category',
        path: '/reports/category',
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_OWN_READ,
          AgentPermissionsEnum.PLAYER_SUB_READ,
        ],
      },
      {
        name: 'By Provider',
        path: '/reports/provider',
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_OWN_READ,
          AgentPermissionsEnum.PLAYER_SUB_READ,
        ],
      },
      {
        name: 'By Agent',
        path: '/reports/agent',
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_OWN_READ,
          AgentPermissionsEnum.AGENT_SUB_READ,
        ],
      },
      {
        name: 'By Player',
        path: '/reports/player',
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_OWN_READ,
          AgentPermissionsEnum.PLAYER_SUB_READ,
        ],
      },
    ],
  },
  {
    name: 'Settings',
    icon: <SettingsIcon />,
    items: [
      {
        name: 'Currencies',
        path: '/settings/currencies',
        permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN],
      },
      { name: 'Branding', path: '/settings/branding', hide: true },
      { name: 'Access Templates', path: '/settings/access-templates' },
      { name: 'Configurations', path: '/settings/configurations', hide: true },
    ],
  },
];
