import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ALL_COUTRIES_GET } from 'api/paths/constants';
import { ApiResponseType, IAllCountries } from 'types';
import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'constant';

type UseAllCountriesStateType = {
  data: IAllCountries[];
  isLoading: boolean;
};

type UseAllCountriesActionsType = {
  get: () => void;
};

const initialState: UseAllCountriesStateType = {
  isLoading: false,
  data: EMPTY_ARRAY,
};

const getAllCountries = createAsyncThunk<ApiResponseType<IAllCountries>>('allCountries/get', async () => {
  return fetcher({
    url: ALL_COUTRIES_GET,
  });
});

const allCountriesSlice = createSlice({
  name: 'allCountries',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder
      .addCase(getAllCountries.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getAllCountries.fulfilled.type, (state, action: PayloadAction<{ data: IAllCountries[] }>): void => {
        state.isLoading = false;
        state.data = action.payload?.data;
      })
      .addCase(getAllCountries.rejected.type, (state): void => {
        state.isLoading = false;
        state.data = EMPTY_ARRAY;
      }),
});

const useAllCountries = (): [UseAllCountriesStateType, UseAllCountriesActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.allCountries);
  const dispatch = useAppDispatch();

  const actions = {
    get: (): void => {
      dispatch(getAllCountries());
    },
  };

  return [state, actions];
};

export { allCountriesSlice };

export default useAllCountries;
