import { FC, Suspense } from 'react';

import ProjectLoading from 'components/sections/ProjectLoading';
import { SwitchRouter } from 'pages/utils';

import routes from './routes';

const Auth: FC = () => (
  <Suspense fallback={<ProjectLoading />}>
    <SwitchRouter routes={routes} />
  </Suspense>
);

export default Auth;
