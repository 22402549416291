import { ForwardedRef, forwardRef, useReducer } from 'react';
import { Box, Typography, TextField, TextFieldProps, InputAdornment, IconButton } from '@mui/material';

import ShowPasswordIcon from 'components/common/icons/bold/Eye';
import HidePasswordIcon from 'components/common/icons/bold/EyeSlash';
import { TranslationKey, translate } from 'utils/translate';

const sx = {
  counter: {
    fontSize: 10,
    textAlign: 'right',
    color: 'custom.grayscale.scale.50',
  },
};

export type InputProps = TextFieldProps & {
  label?: TranslationKey;
  maxLength?: number;
};

const Input = forwardRef(function Input(
  { type, label, required, disabled, fullWidth, multiline, placeholder, InputProps, maxLength, ...props }: InputProps,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element {
  const [isShowPassword, toggleShowPassword] = useReducer((p) => !p, false);

  const isPasswordField = type === 'password';
  const inputType = isPasswordField && !isShowPassword ? 'password' : isShowPassword ? 'text' : type || 'text';
  const inputPlaceholder = placeholder ? placeholder : label ? `Type ${label}` : '';

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography variant="body2" fontWeight={500} mb={0.5} color="custom.neutrals.scale.500">
        {label && translate(label)} {required && '*'}
      </Typography>
      <TextField
        {...props}
        ref={ref}
        type={inputType}
        disabled={disabled}
        fullWidth={fullWidth}
        multiline={multiline}
        placeholder={inputPlaceholder}
        InputProps={{
          endAdornment: isPasswordField && (
            <InputAdornment position="end">
              <IconButton edge="end" onClick={toggleShowPassword}>
                {isShowPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
      />
      {multiline && maxLength && (
        <Typography sx={sx.counter}>
          {(props.value as string)?.length || 0} / {maxLength}
        </Typography>
      )}
    </Box>
  );
});

Input.defaultProps = {
  fullWidth: true,
};

export default Input;
