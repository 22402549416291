import { FC, useCallback } from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import { Box, Fab, Zoom, useScrollTrigger } from '@mui/material';

const ScrollToTopFab: FC = () => {
  const trigger = useScrollTrigger({
    threshold: 100,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <Zoom in={trigger}>
      <Box
        role="presentation"
        sx={{
          position: 'fixed',
          bottom: 50,
          right: 10,
          zIndex: 1,
        }}
      >
        <Fab onClick={scrollToTop} color="primary" size="small" aria-label="Scroll back to top">
          <KeyboardArrowUp fontSize="medium" />
        </Fab>
      </Box>
    </Zoom>
  );
};

export default ScrollToTopFab;
