import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

import useScreen from 'hooks/useScreen';

type Props = {
  isFixed?: boolean;
};

const ProjectLoading: FC<Props> = ({ isFixed = true }) => {
  const { isXs } = useScreen();
  return (
    <Box
      top={0}
      left={0}
      position={isFixed ? 'fixed' : 'static'}
      width={isXs ? '100vw' : '100%'}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default ProjectLoading;
