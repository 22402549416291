import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const ReportsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={22} height={22} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M14.8408 1.83325H7.15909C3.82242 1.83325 1.83325 3.82242 1.83325 7.15908V14.8316C1.83325 18.1774 3.82242 20.1666 7.15909 20.1666H14.8316C18.1683 20.1666 20.1574 18.1774 20.1574 14.8408V7.15908C20.1666 3.82242 18.1774 1.83325 14.8408 1.83325Z"
      fill="inherit"
    />
    <path
      d="M6.30664 17.325C5.93081 17.325 5.61914 17.0133 5.61914 16.6375V14.74C5.61914 14.3642 5.93081 14.0525 6.30664 14.0525C6.68247 14.0525 6.99414 14.3642 6.99414 14.74V16.6375C6.99414 17.0225 6.68247 17.325 6.30664 17.325Z"
      fill="inherit"
    />
    <path
      d="M11 17.3249C10.6242 17.3249 10.3125 17.0133 10.3125 16.6374V12.8333C10.3125 12.4574 10.6242 12.1458 11 12.1458C11.3758 12.1458 11.6875 12.4574 11.6875 12.8333V16.6374C11.6875 17.0224 11.3758 17.3249 11 17.3249Z"
      fill="inherit"
    />
    <path
      d="M15.6934 17.325C15.3175 17.325 15.0059 17.0133 15.0059 16.6375V10.9358C15.0059 10.56 15.3175 10.2483 15.6934 10.2483C16.0692 10.2483 16.3809 10.56 16.3809 10.9358V16.6375C16.3809 17.0225 16.0784 17.325 15.6934 17.325Z"
      fill="inherit"
    />
    <path
      d="M16.3807 5.33481C16.3807 5.28897 16.3624 5.23397 16.3532 5.18814C16.3441 5.15147 16.3349 5.10564 16.3257 5.06897C16.3074 5.03231 16.2799 5.0048 16.2616 4.96814C16.2341 4.93147 16.2066 4.88564 16.1699 4.85814C16.1607 4.84897 16.1607 4.8398 16.1516 4.8398C16.1241 4.82147 16.0966 4.8123 16.0691 4.79397C16.0324 4.76647 15.9866 4.73897 15.9407 4.72064C15.8949 4.7023 15.8491 4.7023 15.8032 4.69314C15.7666 4.68397 15.7391 4.6748 15.7024 4.6748H13.0166C12.6407 4.6748 12.3291 4.98647 12.3291 5.3623C12.3291 5.73814 12.6407 6.0498 13.0166 6.0498H14.1624C11.9807 8.34147 9.23073 9.95481 6.14157 10.734C5.7749 10.8256 5.54573 11.2015 5.6374 11.5681C5.71073 11.8798 5.9949 12.0906 6.30657 12.0906C6.36157 12.0906 6.41657 12.0815 6.47157 12.0723C9.74407 11.2565 12.6682 9.56064 15.0057 7.15897V8.04814C15.0057 8.42397 15.3174 8.73564 15.6932 8.73564C16.0691 8.73564 16.3807 8.42397 16.3807 8.04814V5.3623C16.3807 5.35314 16.3807 5.34397 16.3807 5.33481Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ReportsIcon;
