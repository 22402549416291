import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const EyeSlashIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M21.27 9.18005C20.98 8.72005 20.67 8.29005 20.35 7.89005C19.98 7.42005 19.28 7.38005 18.86 7.80005L15.86 10.8001C16.08 11.4601 16.12 12.2201 15.92 13.0101C15.57 14.4201 14.43 15.5601 13.02 15.9101C12.23 16.1101 11.47 16.0701 10.81 15.8501C10.81 15.8501 9.38001 17.2801 8.35001 18.3101C7.85001 18.8101 8.01001 19.6901 8.68001 19.9501C9.75001 20.3601 10.86 20.5701 12 20.5701C13.78 20.5701 15.51 20.0501 17.09 19.0801C18.7 18.0801 20.15 16.6101 21.32 14.74C22.27 13.2301 22.22 10.6901 21.27 9.18005Z"
      fill="inherit"
    />
    <path
      d="M14.02 9.97989L9.98001 14.0199C9.47001 13.4999 9.14001 12.7799 9.14001 11.9999C9.14001 10.4299 10.42 9.13989 12 9.13989C12.78 9.13989 13.5 9.46989 14.02 9.97989Z"
      fill="inherit"
    />
    <path
      d="M18.25 5.74993L14.86 9.13993C14.13 8.39993 13.12 7.95993 12 7.95993C9.76 7.95993 7.96 9.76993 7.96 11.9999C7.96 13.1199 8.41 14.1299 9.14 14.8599L5.76 18.2499H5.75C4.64 17.3499 3.62 16.1999 2.75 14.8399C1.75 13.2699 1.75 10.7199 2.75 9.14993C3.91 7.32993 5.33 5.89993 6.91 4.91993C8.49 3.95993 10.22 3.42993 12 3.42993C14.23 3.42993 16.39 4.24993 18.25 5.74993Z"
      fill="inherit"
    />
    <path
      d="M14.86 12.0001C14.86 13.5701 13.58 14.8601 12 14.8601C11.94 14.8601 11.89 14.8601 11.83 14.8401L14.84 11.8301C14.86 11.8901 14.86 11.9401 14.86 12.0001Z"
      fill="inherit"
    />
    <path
      d="M21.77 2.22988C21.47 1.92988 20.98 1.92988 20.68 2.22988L2.23 20.6899C1.93 20.9899 1.93 21.4799 2.23 21.7799C2.38 21.9199 2.57 21.9999 2.77 21.9999C2.97 21.9999 3.16 21.9199 3.31 21.7699L21.77 3.30988C22.08 3.00988 22.08 2.52988 21.77 2.22988Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default EyeSlashIcon;
