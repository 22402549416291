import { useCallback } from 'react';
import { AnyObject, Maybe, ObjectSchema, ValidationError } from 'yup';

import { EMPTY_OBJECT } from 'constant';

type Awaited<T> = T extends Promise<infer U> ? U : never;

type ValidatorReturnType<Schema extends Maybe<AnyObject>> = (data: Schema) => Promise<{
  values: Awaited<ReturnType<ObjectSchema<Schema>['validate']>>;
  errors: Record<string, { type: string; message: string }>;
}>;

const useYupValidationResolver = <Schema extends Maybe<AnyObject>>(
  validationSchema: ObjectSchema<Schema>,
): ValidatorReturnType<Schema> =>
  useCallback(
    async (data: Schema) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: EMPTY_OBJECT,
        };
      } catch (_errors) {
        const errors = _errors as ValidationError;

        return {
          values: EMPTY_OBJECT as Awaited<ReturnType<ObjectSchema<Schema>['validate']>>,
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [String(currentError.path)]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            EMPTY_OBJECT,
          ),
        };
      }
    },
    [validationSchema],
  );

export default useYupValidationResolver;
