import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

const useDidMount = (effect: EffectCallback, deps?: DependencyList): void => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      return effect();
    } else {
      didMount.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useDidMount;
