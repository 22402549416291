import * as yup from 'yup';
import type { Schema } from 'yup';
import { matchIsValidTel } from 'mui-tel-input';
import { TransferTypeEnum } from 'enums/entity';
import { PermissionTemplateTypeEnum } from 'enums/accessTemplates';

import { WalletType } from 'types';

export const loginSchema = yup
  .object()
  .shape({
    email: yup.string().trim().required(),
    password: yup.string().trim().label('Password').required(),
  })
  .required();

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().trim().required(),
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const changePasswordForUserSchema = yup.object().shape({
  newPassword: yup.string().trim().required(),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('newPassword')]),
});

export const transferMoneySchema = yup
  .object()
  .shape({
    user: yup
      .object()
      .shape({
        id: yup.string().trim().required(),
        wallets: yup.array<WalletType[]>().required(),
      })
      .required(),
    currencyCode: yup.string().required(),
    transferType: yup.mixed<TransferTypeEnum>().oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW]).required(),
    amount: yup.number().positive().required(),
  })
  .required();

export const transferMoneyToUserAgentSchema = yup
  .object()
  .shape({
    user: yup.string().required(),
    currencyCode: yup.string().required(),
    transferType: yup.mixed<TransferTypeEnum>().oneOf([TransferTypeEnum.DEPOSIT, TransferTypeEnum.WITHDRAW]).required(),
    amount: yup.number().required(),
  })
  .required();

export const createSchemaShape = {
  username: yup.string().trim().required(),
  password: yup.string().trim().required(),
  firstName: yup.string().trim(),
  lastName: yup.string().trim(),
  email: yup.string().trim().email(),
  phone: yup.string().test('invalid', (value) => (value ? matchIsValidTel(value) : true)),
  currencyCodes: yup
    .array()
    .of(yup.string().required())
    .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
    .required(),
};

export const createAgentSchema = yup
  .object()
  .shape({
    ...createSchemaShape,
    permissionTemplateId: yup.number().required(),
  })
  .required();

export const createPlayerSchema = yup
  .object()
  .shape({
    ...createSchemaShape,
    permissionTemplateId: yup.number(),
  })
  .required();

export const createPartnerSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
  })
  .required();

export const updatePartnerGeneralSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    domain: yup.string(),
    status: yup.boolean().required(),
    contactEmail: yup.string().trim().email(),
  })
  .required();

export const updateAgentGeneralSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    username: yup.string().required().label('Username'),
    phoneNumber: yup.string().optional().label('Phone Number'),
    email: yup.string().required().label('Password'),
    birthDate: yup.string().nullable().optional().label('Birth Date'),
    createdAt: yup.string().required().label('Reg. Date'),
    country: yup.string().optional().label('Country'),
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
  })
  .required();
export const updateAgentSettingsSchema = yup
  .object()
  .shape({
    externalPlayerId: yup.string().required().label('External Id'),
    agent: yup.string().optional().label('Agent'),
    agentId: yup.number().optional().label('AgentId'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

// TODO: VALIDATION_SCHEMA_TRANSLATION
// FIXME: add translation staff
export const updatePlayerGeneralSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional().label('First Name'),
    lastName: yup.string().optional().label('Last Name'),
    username: yup.string().required().label('Username'),
    phoneNumber: yup.string().optional().label('Phone Number'),
    email: yup.string().required().label('Password'),
    birthDate: yup.string().nullable().optional().label('Birth Date'),
    createdAt: yup.string().required().label('Reg. Date'),
    country: yup.string().optional().label('Country'),
    currencyCodes: yup
      .array()
      .of(yup.string().required())
      .test('invalid', (value: string[] | undefined) => value && value?.length > 0)
      .required(),
  })
  .required();
export const updatePlayerSettingsSchema = yup
  .object()
  .shape({
    externalPlayerId: yup.string().required().label('External Id'),
    agent: yup.string().optional().label('Agent'),
    agentId: yup.number().optional().label('AgentId'),
    status: yup.boolean().required().label('Status'),
  })
  .required();

export const updatePartnerPlatformSchema = yup
  .object()
  .shape({
    providerId: yup.number().required(),
    meta: yup.string(),
  })
  .required();

export const createAccessTemplateSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    type: yup.mixed<PermissionTemplateTypeEnum>().required(),
    layer: yup
      .number()
      .positive()
      .integer()
      .when('type', ([type], schema) =>
        type === PermissionTemplateTypeEnum.LAYERED ? schema.required() : schema.nullable(),
      ),
    permissions: yup
      .array()
      .of(yup.mixed<boolean>())
      .test('required', (value) => value?.some((i) => i)),
    grantPermissions: yup.array().of(yup.mixed<boolean>()),
  })
  .required();

const casinoLimitTypeItemsSchema = (key: string): Schema =>
  yup.number().when('_type', ([_type], schema) => (_type === key ? schema.required() : schema.nullable()));

export const casinoLimitSchema = yup
  .object()
  .shape({
    _type: yup.string().label('Type').required(),
    amount: yup.number().label('Amount').required(),
    name: yup.string().trim().label('Name').required(),
    currency: yup.number().label('Currency').required(),
    gameId: casinoLimitTypeItemsSchema('gameId').label('Game'),
    sportId: casinoLimitTypeItemsSchema('sportId').label('Sport'),
    categoryId: casinoLimitTypeItemsSchema('categoryId').label('Category'),
    providerId: casinoLimitTypeItemsSchema('providerId').label('Provider'),
  })
  .required();

export const tournamentSchema = yup
  .object()
  .shape({
    category: yup.string().trim().label('category').required(),
    startDate: yup.string().trim().label('startDate').required(),
    endDate: yup.string().trim().label('endDate').required(),
    prizePool: yup.number().label('prizePool').required(),
    translations: yup
      .array()
      .of(
        yup.object().shape({
          iconUrl: yup.string().trim().label('iconUrl').required(),
          name: yup.string().trim().label('name').required(),
          description: yup.string().trim().label('description').required(),
          languageId: yup.number().label('languageId').required(),
        }),
      )
      .required(),
    tournamentTemplateId: yup.number().label('tournamentTemplateId').required(),
  })
  .required();
