import { FC, ReactNode } from 'react';

// const ALL_PAGES_QUERY = {page: '1', size: String(Number.MAX_SAFE_INTEGER)};

const Preloader: FC<{ children: ReactNode }> = ({ children }) => {
  // const [, tags] = useTags();

  // const [, categories] = useCategories();
  // const [, websiteSports] = useWebsiteSports();
  // const [, currencies] = useWebsiteCurrencies();
  // const [, websiteProviders] = useWebsiteProviders();
  // const [, websiteGamesProduct] = useWebsiteGamesProduct();
  //
  // useMount(() => {
  //   // TEMPORARY HARD CODED MAX LIMIT
  //   tags.get(ALL_PAGES_QUERY);
  //   categories.get(ALL_PAGES_QUERY);
  //   currencies.get(ALL_PAGES_QUERY);
  //   websiteSports.get(ALL_PAGES_QUERY);
  //   websiteProviders.get(ALL_PAGES_QUERY);
  //   websiteGamesProduct.get(ALL_PAGES_QUERY);
  // });

  return <>{children}</>;
};

export default Preloader;
