import { SystemStyleObject } from '@mui/system';

export default {
  notificationsList: {
    px: 2,
    top: 50,
    margin: 0,
    left: '50%',
    width: '100%',
    zIndex: 3000,
    maxWidth: 572,
    position: 'fixed',
    transform: 'translate(-50%, 0)',
  },
  toasterRoot: ({ color }: { color: string }): SystemStyleObject => ({
    py: 1,
    px: 2,
    // opacity: 0.8,
    borderRadius: 1,
    bgcolor: 'common.white',
    boxShadow: '0px 9px 22px rgba(20, 81, 172, 0.08)',
    position: 'relative',
    borderBottomColor: color,
    overflow: 'hidden',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: 5,
      width: '100%',
      bgcolor: color,
    },
  }),
  toasterContent: {
    mb: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toasterIcon: ({ color }: { color: string }): SystemStyleObject => ({
    mr: 2,
    display: 'flex',
    alignItems: 'center',
    color: color,
    justifyContent: 'space-between',
  }),
  toasterText: ({ color }: { color: string }): SystemStyleObject => ({
    flex: 1,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '26px',
    color: color,
  }),
};
