import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SearchIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={20} height={20} viewBox="0 0 20 20" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.5 12.5L17.5 17.5L12.5 12.5Z" fill="inherit" />
    <path
      d="M13.2071 11.7929C12.8166 11.4024 12.1834 11.4024 11.7929 11.7929C11.4024 12.1834 11.4024 12.8166 11.7929 13.2071L13.2071 11.7929ZM16.7929 18.2071C17.1834 18.5976 17.8166 18.5976 18.2071 18.2071C18.5976 17.8166 18.5976 17.1834 18.2071 16.7929L16.7929 18.2071ZM8.33333 13.1667C5.66396 13.1667 3.5 11.0027 3.5 8.33333H1.5C1.5 12.1073 4.55939 15.1667 8.33333 15.1667V13.1667ZM3.5 8.33333C3.5 5.66396 5.66396 3.5 8.33333 3.5V1.5C4.55939 1.5 1.5 4.55939 1.5 8.33333H3.5ZM8.33333 3.5C11.0027 3.5 13.1667 5.66396 13.1667 8.33333H15.1667C15.1667 4.55939 12.1073 1.5 8.33333 1.5V3.5ZM13.1667 8.33333C13.1667 11.0027 11.0027 13.1667 8.33333 13.1667V15.1667C12.1073 15.1667 15.1667 12.1073 15.1667 8.33333H13.1667ZM11.7929 13.2071L16.7929 18.2071L18.2071 16.7929L13.2071 11.7929L11.7929 13.2071Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default SearchIcon;
