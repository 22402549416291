import { ChangeEvent } from 'react';
import { Control, FieldPath, FieldValues, useController } from 'react-hook-form';
import { FormControl, FormHelperText, FormControlLabel, Checkbox, Typography } from '@mui/material';

import { TranslationKey, translate } from 'utils/translate';

interface FormCheckboxProps<TFieldValues extends FieldValues = FieldValues> {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
  label?: TranslationKey;
  required?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium';
  conditionalRequired?: boolean;
  labelStyles?: Record<string, string>;
}

const FormCheckbox = <TFieldValues extends FieldValues = FieldValues>({
  name,
  size,
  label,
  control,
  required,
  disabled,
}: FormCheckboxProps<TFieldValues>): JSX.Element | null => {
  const {
    field: { onChange, value, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const checked = Boolean(value);

  if (!name || !control) {
    return null;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.checked);
  };

  return (
    <FormControl error={!!error} disabled={disabled}>
      <FormControlLabel
        disabled={disabled}
        label={
          <Typography fontSize={14} fontWeight={500} noWrap>
            {label && translate(label)} {required ? '*' : ''}
          </Typography>
        }
        control={
          <Checkbox {...field} name={name} size={size} value={checked} checked={checked} onChange={handleChange} />
        }
      />
      {!!error && <FormHelperText error>{error.message}</FormHelperText>}
    </FormControl>
  );
};

export default FormCheckbox;
