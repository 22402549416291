import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

import { TranslationKey } from 'utils/translate';
import Input from 'components/common/Input';

interface FormInputProps<T extends FieldValues> {
  label?: TranslationKey;
  control: Control<T>;
  maxLength?: number;
}

const FormInput = <T extends FieldValues>({
  name,
  control,
  disabled,
  ...props
}: TextFieldProps & FormInputProps<T>): JSX.Element | null => {
  const {
    fieldState: { error },
    field: { ref, value = '', ...field },
  } = useController<T>({
    name: name as Path<T>,
    control,
  });

  if (!name || !control) {
    return null;
  }

  return (
    <Input
      {...props}
      {...field}
      ref={ref}
      name={name}
      error={!!error}
      value={value}
      disabled={disabled}
      helperText={
        <>
          {error?.message} {props.helperText}
        </>
      }
    />
  );
};

export default FormInput;
