import { createSvgIcon } from '@mui/material/utils';

const Rectangle = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="transparent"
      d="M18 2H6C3.79086 2 2 3.79086 2 6V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V6C22 3.79086 20.2091 2 18 2ZM16.592 9.46049"
      style={{
        transform: 'scale(0.95)',
        stroke: 'currentColor',
        strokeLinejoin: 'round',
        transformOrigin: 'center',
      }}
    />
  </>,
  'Rectangle',
);

export default Rectangle;
