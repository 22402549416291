import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const DotIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" {...props}>
    <circle cx="12" cy="12" r="3" fill="inherit" />
  </SvgIcon>
);

export default DotIcon;
