import { FC, useMemo } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Stack from '@mui/material/Stack';

import { IAgent } from 'store/auth';
import { IPlayerListDTO } from 'store/types/player';
import { WalletType } from 'types';
import { translate } from 'utils/translate';
import { IAgentListDTO } from 'store/types/agent';

type BalanceGroupProps = {
  sender?: IAgent & { wallets?: WalletType[] };
  receiver?: (IAgent & { wallets: WalletType[] }) | IPlayerListDTO | IAgentListDTO;
  selectedCurrencyCode: string;
};

const BalanceGroup: FC<BalanceGroupProps> = ({ sender, receiver, selectedCurrencyCode }) => {
  if (!sender) {
    return <></>;
  }
  const senderInfo = useMemo(() => {
    const info = {
      id: sender?.id,
      username: sender?.username || '',
      currencyCode: sender?.wallets?.at(0)?.currencyCode || '',
      balance: sender?.wallets?.at(0)?.balance ? sender?.wallets?.at(0)?.balance.toLocaleString() : '0',
    };
    if (selectedCurrencyCode) {
      const selectedWallet = sender?.wallets && sender.wallets.find((w) => w.currencyCode === selectedCurrencyCode);

      info.currencyCode = (sender?.wallets && selectedWallet?.currencyCode) || '';
      info.balance = (sender?.wallets && selectedWallet?.balance && selectedWallet?.balance.toLocaleString()) || '';
    }
    return info;
  }, [sender, selectedCurrencyCode]);

  const receiverInfo = useMemo(() => {
    const info = {
      id: receiver?.id || '',
      username: receiver?.username || '',
      currencyCode: receiver?.wallets?.at(0)?.currencyCode || '',
      balance: receiver?.wallets?.at(0)?.balance?.toLocaleString() || '',
    };
    if (selectedCurrencyCode) {
      info.currencyCode =
        (receiver?.wallets && receiver.wallets.find((w) => w.currencyCode === selectedCurrencyCode)?.currencyCode) ||
        '';
    }
    return info;
  }, [receiver?.id, receiver?.username, receiver?.wallets, selectedCurrencyCode]);

  return (
    <TableContainer component={Paper}>
      <Table size={'small'} aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={12}>
              {translate('Balance Info')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={12}>
              {senderInfo.username && senderInfo.id ? (
                <Stack direction="row" justifyContent="flex-start">
                  {senderInfo.username && senderInfo.id ? `${senderInfo.username} (${senderInfo.id})` : ''}
                  {`: ${senderInfo.balance || 0} ${senderInfo.currencyCode}`}
                </Stack>
              ) : (
                ''
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={12}>
              {receiverInfo.username && receiverInfo.id ? (
                <Stack direction="row" justifyContent="flex-start">
                  {`${receiverInfo.username} (${receiverInfo.id})`}
                  {`: ${receiverInfo.balance || 0} ${receiverInfo.currencyCode}`}
                </Stack>
              ) : (
                ''
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BalanceGroup;
