import { ApiResponseType, ITransferMoney } from 'types';
import fetcher from 'utils/fetcher';

export const createTransferMoney = (
  transfer: Partial<ITransferMoney>,
  endpoint: string,
): Promise<ApiResponseType<ITransferMoney>> =>
  fetcher<ITransferMoney, Partial<ITransferMoney>>({
    method: 'PUT',
    url: endpoint,
    body: transfer,
  });
