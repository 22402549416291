import { FC, MouseEventHandler, useState } from 'react';
import { Button, Menu, MenuItem, Typography } from '@mui/material';

import ArrowUpIcon from 'components/common/icons/ArrowUp';
import ArrowDownIcon from 'components/common/icons/ArrowDown';
import usePartners from 'store/partner';
import useAuth from 'store/auth';

interface IFakeWebsite {
  id: number;
  name: string;
}

const WebsitesMenu: FC = () => {
  const [authState, authActions] = useAuth();
  const [partners] = usePartners();
  const [selected, setSelected] = useState(
    authState?.data?.agent?.activePartnerId || authState?.data?.agent?.partner?.id,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const selectedName = partners.data.find((item) => item.id === selected)?.name;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleSelect = async (item: IFakeWebsite): Promise<void> => {
    try {
      await authActions.changePartner(item.id);
      setSelected(item.id);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
    handleClose();
  };

  return (
    <>
      <Button color="inherit" onClick={handleClick} endIcon={open ? <ArrowUpIcon /> : <ArrowDownIcon />} sx={{ p: 0 }}>
        <Typography component="span" variant="body1" fontWeight={500}>
          {selectedName}
        </Typography>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{ paper: { elevation: 4, sx: { width: 280 } } }}
      >
        {partners.data.map((item) => (
          <MenuItem selected={item.id === selected} key={item.id} onClick={(): Promise<void> => handleSelect(item)}>
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default WebsitesMenu;
