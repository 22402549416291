import { SystemStyleObject } from '@mui/system';

const sx = {
  root: {
    width: 300,
    height: '100vh',
    bgcolor: 'custom.neutrals.scale.1000',
    position: 'fixed',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  head: {
    px: 3,
    height: 60,
    bgcolor: 'custom.neutrals.scale.900',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    flex: 1,
    py: 3,
    px: 2,
    overflowY: 'auto',
  },
  menuItem: ({ selected }: { selected: boolean }): SystemStyleObject => ({
    px: 1.5,
    py: 0.75,
    height: 40,
    borderRadius: 0.75,
    color: selected ? 'custom.neutrals.scale.200' : 'custom.neutrals.scale.300',
    bgcolor: selected ? 'custom.neutrals.scale.800' : 'transparent',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      color: selected ? 'custom.primary.scale.400' : 'custom.neutrals.scale.400',
    },
  }),
};

export default sx;
