import { FC } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { WalletType } from 'types';
import { translate } from 'utils/translate';
import valueToPrecision from 'utils/valueToPrecision';

type Props = {
  open: boolean;
  onClose: () => void;
  wallets: WalletType[];
};

const BalanceBoxMobile: FC<Props> = ({ open, onClose, wallets }) => {
  const toggleDrawer = () => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    onClose();
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer()}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{ width: 'auto' }} role="presentation">
        <List>
          {wallets.map((item) => (
            <ListItem key={item.currencyCode || item.id} sx={{ display: 'flex' }}>
              <ListItemText primary={`${translate('Balance')}:`} sx={{ mr: 2, flexGrow: 1 }} />
              <ListItemText primary={`${item.currencyCode} ${valueToPrecision(item.balance)}`} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default BalanceBoxMobile;
