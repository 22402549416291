import { FC, useState } from 'react';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { WalletType } from 'types';
import EmptyWalletIcon from 'components/common/icons/bulk/EmptyWallet';
import useScreen from 'hooks/useScreen';
import valueToPrecision from 'utils/valueToPrecision';

import BalanceBoxMobile from './BalanceBoxMobile';
import BalanceBox from './BalanceBox';

type Props = {
  wallets: WalletType[];
};

const BalanceMenu: FC<Props> = ({ wallets }) => {
  const theme = useTheme();
  const { isMobile } = useScreen();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  if (wallets?.length === 0) {
    return null;
  }

  return (
    <>
      {isMobile ? (
        <>
          <IconButton onClick={handleClick}>
            <EmptyWalletIcon />
          </IconButton>
          <BalanceBoxMobile open={!!anchorEl} onClose={handleClose} wallets={wallets} />
        </>
      ) : (
        <>
          <Typography
            aria-describedby={id}
            onClick={handleClick}
            sx={{
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              fontSize: theme.typography.body2.fontSize,
              cursor: 'pointer',
            }}
          >
            {wallets[0]?.currencyCode ?? ''} {valueToPrecision(wallets[0]?.balance)}
          </Typography>
          <BalanceBox anchorEl={anchorEl} onClose={handleClose} wallets={wallets} />
        </>
      )}
    </>
  );
};

export default BalanceMenu;
