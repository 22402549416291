import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const MoneyCircle: FC<SvgIconProps> = (props): JSX.Element => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      opacity="0.4"
      d="M12 21.8999C17.5228 21.8999 22 17.4228 22 11.8999C22 6.37705 17.5228 1.8999 12 1.8999C6.47715 1.8999 2 6.37705 2 11.8999C2 17.4228 6.47715 21.8999 12 21.8999Z"
      fill="inherit"
    />
    <path
      d="M14.26 12L12.75 11.47V8.08H13.11C13.92 8.08 14.58 8.79 14.58 9.66C14.58 10.07 14.92 10.41 15.33 10.41C15.74 10.41 16.08 10.07 16.08 9.66C16.08 7.96 14.75 6.58 13.11 6.58H12.75V6C12.75 5.59 12.41 5.25 12 5.25C11.59 5.25 11.25 5.59 11.25 6V6.58H10.6C9.12003 6.58 7.91003 7.83 7.91003 9.36C7.91003 11.15 8.95003 11.72 9.74003 12L11.25 12.53V15.91H10.89C10.08 15.91 9.42003 15.2 9.42003 14.33C9.42003 13.92 9.08003 13.58 8.67003 13.58C8.26003 13.58 7.92003 13.92 7.92003 14.33C7.92003 16.03 9.25003 17.41 10.89 17.41H11.25V18C11.25 18.41 11.59 18.75 12 18.75C12.41 18.75 12.75 18.41 12.75 18V17.42H13.4C14.88 17.42 16.09 16.17 16.09 14.64C16.08 12.84 15.04 12.27 14.26 12ZM10.24 10.59C9.73003 10.41 9.42003 10.24 9.42003 9.37C9.42003 8.66 9.95003 8.09 10.61 8.09H11.26V10.95L10.24 10.59ZM13.4 15.92H12.75V13.06L13.76 13.41C14.27 13.59 14.58 13.76 14.58 14.63C14.58 15.34 14.05 15.92 13.4 15.92Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default MoneyCircle;
