import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useMemo } from 'react';

import { RootState } from 'store/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { IAgentListDTO } from 'store/types/agent';
import { CREATE_AGENT_GET } from 'api/paths/creators';
import { ApiResponseType, ISaveOptions, PaginatedResponse } from 'types';
import createSaveReducer from 'utils/createSaveReducer';
import { EMPTY_ARRAY } from 'constant';

type UseAgentStateType = {
  data: PaginatedResponse<IAgentListDTO>;
  isLoading: boolean;
};

type UseAgentActionsType = {
  get: (id: number) => Promise<{ data: IAgentListDTO } | undefined>;
  getLocalById: (id: number) => IAgentListDTO | undefined;
  save: (options: ISaveOptions<IAgentListDTO>) => void;
  addAgent: (data: IAgentListDTO) => void;
};

const initialState: UseAgentStateType = {
  isLoading: false,
  data: {
    data: EMPTY_ARRAY,
    count: 0,
  },
};

const getAgent = createAsyncThunk<ApiResponseType<IAgentListDTO>, number>('agent/get', async (id: number) => {
  return fetcher({
    url: CREATE_AGENT_GET(id),
  });
});

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    save: createSaveReducer(),
    addAgent(state, action: PayloadAction<IAgentListDTO>): void {
      state.data.data = [action.payload, ...state.data.data];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAgent.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(getAgent.fulfilled.type, (state, action: PayloadAction<{ data: IAgentListDTO }>) => {
        state.isLoading = false;
        state.data = { ...state.data, data: state.data.data.concat(action.payload?.data) };
      })
      .addCase(getAgent.rejected.type, (state): void => {
        state.isLoading = false;
      }),
});

const useAgent = (): [UseAgentStateType, UseAgentActionsType] => {
  const state = useAppSelector((storeState: RootState) => storeState.agent);
  const dispatch = useAppDispatch();

  const actions = useMemo(
    () => ({
      get: async (id: number): Promise<{ data: IAgentListDTO } | undefined> => {
        const agent = await dispatch(getAgent(id));
        return agent.payload as Promise<{ data: IAgentListDTO } | undefined>;
      },
      getLocalById: (id: number) => state.data.data.find((agent) => agent.id === id),
      save: (options: ISaveOptions<IAgentListDTO>): void => {
        dispatch(agentSlice.actions.save(options));
      },
      addAgent: (model: IAgentListDTO): void => {
        dispatch(agentSlice.actions.addAgent(model));
      },
    }),
    [dispatch, state.data.data],
  );

  return [state, actions];
};

export { agentSlice };

export default useAgent;
