import { BASE_API, HttpStatusCode } from 'configs';
import { authStorage } from 'store/storage';
import { ApiResponseType } from 'types';

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'OPTIONS' | 'PATCH';

type Fetcher<T> = {
  url: string;
  params?: Record<string, string | string[]>;
  method?: Method;
  headers?: HeadersInit;
  body?: T;
};

export type DispatchPayloadAction<P> = {
  payload: P;
  error: {
    message: string;
  };
};

function createContext<T>(options: Fetcher<T>): Fetcher<T> {
  const accessToken = authStorage.get()?.accessToken;

  const headers = new Headers({
    accept: 'application/json',
    ...(accessToken && { Authorization: `${BASE_API.TOKEN_TYPE} ${accessToken}` }),
    ...options.headers,
  });

  if (!(options.body instanceof FormData)) {
    headers.append('Content-Type', 'application/json');
  }

  return { method: 'GET', ...options, headers };
}

const createEndpoint = (url: string, params?: Record<string, string | string[]>): string => {
  const endpoint = new URL(`${BASE_API.URL}${url}`);
  if (!params) {
    return endpoint.toString();
  }

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => endpoint.searchParams.append(key, v));
      return;
    }
    endpoint.searchParams.append(key, value);
  });

  return endpoint.toString();
};

async function fetcher<ResponseType, BodyType = void>(
  options: Fetcher<BodyType>,
): Promise<ApiResponseType<ResponseType>> {
  const { url, method, headers, body, params } = createContext(options);

  const endpoint = createEndpoint(url, params);

  return new Promise<ApiResponseType<ResponseType>>((resolve, reject) => {
    return fetch(endpoint, {
      method,
      headers,
      body: body instanceof FormData ? body : JSON.stringify(body),
    })
      .then(async (response) => {
        if (response.status === HttpStatusCode.UNAUTHORIZED) {
          authStorage.clear();
        } else if ([HttpStatusCode.ACCEPTED].includes(response.status)) {
          resolve('' as unknown as ApiResponseType<ResponseType>);
        } else {
          const data = await response?.json();

          if (response.ok) {
            resolve(data);
          } else {
            reject(data);
          }
        }
      })
      .catch((error) => {
        reject(error?.response);
      });
  });
}

export default fetcher;
