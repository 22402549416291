import { FC, useState, MouseEvent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Stack, ToggleButtonGroup, ToggleButton, Box, SelectChangeEvent } from '@mui/material';
import Slide from '@mui/material/Slide';
import { EntityEnum, TransferTypeEnum } from 'enums/entity';

import useToaster from 'hooks/useToaster';
import Modal from 'components/common/Modal';
import TransferIcon from 'components/common/icons/bulk/Transfer';
import Form, { FormNumberInput, FormSelect, Autocomplete } from 'components/common/Form';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import { transferMoneySchema } from 'configs/validationSchemes';
import { translate, TranslationKey } from 'utils/translate';
import { TRANSFER_TYPE_OPTIONS } from 'configs';
import { GET_OWN_AGENTS, GET_OWN_PLAYERS } from 'api/paths/constants';
import {
  CREATE_AGENT_DEPOSIT_PUT,
  CREATE_AGENT_WITHDRAW_PUT,
  CREATE_PLAYER_DEPOSIT_PUT,
  CREATE_PLAYER_WITHDRAW_PUT,
} from 'api/paths/creators';
import { ITransferMoney, WalletType } from 'types/transfer';
import { ENTITY_TYPES_INFO } from 'constant';
import { createTransferMoney } from 'api/requests/transfer';
import { OptionType } from 'types/utils';
import { ApiErrorType } from 'types';
import useScreen from 'hooks/useScreen';
import useAuth, { IAgent } from 'store/auth';
import { IPlayerListDTO } from 'store/types/player';
import BalanceGroup from 'components/common/BalanceGroup';

const prepareTransferEndpoint = (
  user: { id: string },
  entityType: EntityEnum,
  transferType: TransferTypeEnum,
): string => {
  if (transferType === TransferTypeEnum.DEPOSIT && entityType === EntityEnum.AGENT) {
    return CREATE_AGENT_DEPOSIT_PUT(user.id);
  } else if (transferType === TransferTypeEnum.DEPOSIT && entityType === EntityEnum.PLAYER) {
    return CREATE_PLAYER_DEPOSIT_PUT(user.id);
  } else if (transferType === TransferTypeEnum.WITHDRAW && entityType === EntityEnum.AGENT) {
    return CREATE_AGENT_WITHDRAW_PUT(user.id);
  } else if (transferType === TransferTypeEnum.WITHDRAW && entityType === EntityEnum.PLAYER) {
    return CREATE_PLAYER_WITHDRAW_PUT(user.id);
  }

  return '';
};

interface Props {
  onClose: () => void;
}

const TransferModal: FC<Props> = ({ onClose }) => {
  const { isMobile } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [createType, setCreateType] = useState<EntityEnum | null>(null);
  const [currencyOptions, setCurrencyOptions] = useState<OptionType[]>([]);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('');
  const [receiver, setReceiver] = useState<IAgent | IPlayerListDTO>();
  const [authState, authActions] = useAuth();
  const notify = useToaster();

  const resolver = useYupValidationResolver<ITransferMoney>(transferMoneySchema);
  const { control, reset, trigger, setValue, handleSubmit } = useForm<ITransferMoney>({
    resolver,
    defaultValues: {
      user: null,
      currencyCode: '',
      transferType: TRANSFER_TYPE_OPTIONS?.[0]?.id,
      amount: undefined,
    },
  });

  const handleBack = (): void => {
    setCreateType(null);
    reset({
      user: null,
      currencyCode: '',
      transferType: undefined,
      amount: undefined,
    });
  };

  const handleChangeType = (event: MouseEvent<HTMLElement>, newAlignment: EntityEnum): void => {
    setCreateType(newAlignment);
  };

  const handleCreateSubmit = async (data: ITransferMoney): Promise<void> => {
    setIsLoading(true);
    if (!data.user || !createType) {
      return;
    }
    try {
      const endpoint = prepareTransferEndpoint(data.user, createType, data.transferType);
      await createTransferMoney(
        {
          amount: data.amount,
          currencyCode: data.currencyCode,
        },
        endpoint,
      );
      setIsLoading(false);
      notify({
        message: 'Success',
        type: 'success',
      });
      authActions.addWallets();
      onClose();
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const handleUserChange = async (data: unknown): Promise<void> => {
    const wallets = (data as { wallets: WalletType[] })?.wallets ?? [];
    setCurrencyOptions([
      ...(wallets?.map((i: WalletType) => {
        return { name: i.currencyCode, id: i.currencyCode };
      }) || []),
    ] as OptionType[]);
    const firstWalletEntity = wallets[0]?.currencyCode || '';
    setValue('currencyCode', firstWalletEntity);
    setSelectedCurrencyCode(firstWalletEntity);
    setReceiver(data as IPlayerListDTO | IAgent);
    await trigger('currencyCode');
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  const handleChangeCurrencyCode = useCallback((event: SelectChangeEvent<unknown>): void => {
    setSelectedCurrencyCode(event.target.value as string);
  }, []);

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Apply"
      icon={<TransferIcon />}
      title="Money Transfer"
      isLoading={isLoading}
      showBack={!!createType}
      isSaveDisabled={!createType}
      onBack={handleBack}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Slide direction="right" in={!createType} mountOnEnter unmountOnExit timeout={{ appear: 0, enter: 0, exit: 0 }}>
          <Stack alignItems="center">
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={createType}
              onChange={handleChangeType}
              aria-label="Platform"
            >
              <ToggleButton value={EntityEnum.PLAYER}>
                {translate(ENTITY_TYPES_INFO[EntityEnum.PLAYER].label)}
              </ToggleButton>
              <ToggleButton value={EntityEnum.AGENT}>
                {translate(ENTITY_TYPES_INFO[EntityEnum.AGENT].label)}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Slide>
        <Slide
          direction="right"
          in={!!createType}
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 300, enter: 300, exit: 0 }}
        >
          <Box>
            <Stack spacing={2}>
              <Form onSubmit={handleSubmit(handleCreateSubmit)}>
                <Stack spacing={2}>
                  <Autocomplete
                    required
                    isAsync
                    name="user"
                    control={control}
                    optionLabel="username"
                    searchTerm="username"
                    label={
                      createType ? ENTITY_TYPES_INFO[createType].label : ENTITY_TYPES_INFO[EntityEnum.PLAYER].label
                    }
                    pathName={createType === EntityEnum.PLAYER ? GET_OWN_PLAYERS : GET_OWN_AGENTS}
                    onChange={handleUserChange}
                  />
                  <FormSelect
                    required
                    control={control}
                    name="currencyCode"
                    label="Currency"
                    onChange={handleChangeCurrencyCode}
                    options={currencyOptions}
                  />
                  <FormSelect
                    required
                    control={control}
                    name="transferType"
                    label="Type"
                    options={TRANSFER_TYPE_OPTIONS}
                  />
                  <FormNumberInput required control={control} name="amount" label="Amount" />
                </Stack>
              </Form>
              <BalanceGroup
                sender={
                  { ...authState.data?.agent, ...{ wallets: authState.data?.wallets } } as IAgent & {
                    wallets: WalletType[];
                    status: string;
                  }
                }
                receiver={receiver as IPlayerListDTO}
                selectedCurrencyCode={selectedCurrencyCode}
              />
            </Stack>
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
};

export default TransferModal;
