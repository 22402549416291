import { Shadows } from '@mui/material';

/**
 * Shadows
 * Unliment's shadows are designed to bring depth and realism to your designs.
 * Our curated shadow collection is available for both dark and light modes, ensuring seamless adaptation to different contexts.
 */

export const shadows: Shadows = [
  'none',
  '0px 1px 2px 0px rgba(15, 15, 15, 0.05)',
  '0px 1px 2px 0px rgba(15, 15, 15, 0.06), 0px 1px 3px 0px rgba(15, 15, 15, 0.10)',
  '0px 2px 4px -2px rgba(15, 15, 15, 0.06), 0px 4px 8px -2px rgba(15, 15, 15, 0.10)',
  '0px 4px 6px -2px rgba(15, 15, 15, 0.03), 0px 12px 16px -4px rgba(15, 15, 15, 0.08)',
  '0px 8px 8px -4px rgba(15, 15, 15, 0.03), 0px 20px 24px -4px rgba(15, 15, 15, 0.08)',
  '0px 24px 48px -12px rgba(15, 15, 15, 0.18)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',

  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
  '0px 32px 64px -12px rgba(15, 15, 15, 0.14)',
];

export const dark_shadows: Shadows = [
  'none',
  '0px 1px 2px 0px rgba(82, 82, 82, 0.05)',
  '0px 1px 2px 0px rgba(82, 82, 82, 0.06), 0px 1px 3px 0px rgba(82, 82, 82, 0.10)',
  '0px 2px 4px -2px rgba(82, 82, 82, 0.06), 0px 4px 8px -2px rgba(82, 82, 82, 0.10)',
  '0px 4px 6px -2px rgba(82, 82, 82, 0.03), 0px 12px 16px -4px rgba(82, 82, 82, 0.08)',
  '0px 8px 8px -4px rgba(82, 82, 82, 0.03), 0px 20px 24px -4px rgba(82, 82, 82, 0.08)',
  '0px 24px 48px -12px rgba(82, 82, 82, 0.18)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',

  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
  '0px 32px 64px -12px rgba(82, 82, 82, 0.14)',
];
