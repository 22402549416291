import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const SettingsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M1.83301 11.8067V10.1933C1.83301 9.24 2.61217 8.45166 3.57467 8.45166C5.23384 8.45166 5.91217 7.27833 5.07801 5.83916C4.60134 5.01416 4.88551 3.94166 5.71967 3.465L7.30551 2.5575C8.02967 2.12666 8.96467 2.38333 9.39551 3.1075L9.49634 3.28166C10.3213 4.72083 11.678 4.72083 12.5122 3.28166L12.613 3.1075C13.0438 2.38333 13.9788 2.12666 14.703 2.5575L16.2888 3.465C17.123 3.94166 17.4072 5.01416 16.9305 5.83916C16.0963 7.27833 16.7747 8.45166 18.4338 8.45166C19.3872 8.45166 20.1755 9.23083 20.1755 10.1933V11.8067C20.1755 12.76 19.3963 13.5483 18.4338 13.5483C16.7747 13.5483 16.0963 14.7217 16.9305 16.1608C17.4072 16.995 17.123 18.0583 16.2888 18.535L14.703 19.4425C13.9788 19.8733 13.0438 19.6167 12.613 18.8925L12.5122 18.7183C11.6872 17.2792 10.3305 17.2792 9.49634 18.7183L9.39551 18.8925C8.96467 19.6167 8.02967 19.8733 7.30551 19.4425L5.71967 18.535C4.88551 18.0583 4.60134 16.9858 5.07801 16.1608C5.91217 14.7217 5.23384 13.5483 3.57467 13.5483C2.61217 13.5483 1.83301 12.76 1.83301 11.8067Z"
      fill="inherit"
    />
    <path
      d="M10.9997 13.9792C12.645 13.9792 13.9788 12.6453 13.9788 11C13.9788 9.35465 12.645 8.02083 10.9997 8.02083C9.35433 8.02083 8.02051 9.35465 8.02051 11C8.02051 12.6453 9.35433 13.9792 10.9997 13.9792Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default SettingsIcon;
