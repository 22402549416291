import { PayloadAction } from '@reduxjs/toolkit';

import { ISaveOptions, PaginatedResponse } from 'types';

const createSaveReducer =
  <Data extends { id: number }, StoreSliceType extends { data: PaginatedResponse<Data> }>() =>
  (state: StoreSliceType, action: PayloadAction<ISaveOptions<Data>>): void => {
    const newData = action.payload.data.filter(
      (dataItem) => !state.data.data.find((existingDataItem) => existingDataItem.id === dataItem.id),
    );
    if (action.payload.replace) {
      state.data.data = action.payload.data;
    } else {
      state.data.data.push(...newData);
    }
    if (action.payload.totalCount) {
      state.data.count = action.payload.totalCount;
    } else {
      state.data.count += newData.length;
    }
  };

export default createSaveReducer;
