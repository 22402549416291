import { FC } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import { translate } from 'utils/translate';

type Props = {
  username?: string;
  openSelectList: boolean;
  handleBack: () => void;
};

const ProfileDrawerHeader: FC<Props> = ({ username, openSelectList, handleBack }) => {
  return (
    <Stack flexDirection="row" p={2} alignItems="center" justifyContent="space-between" flexWrap="wrap">
      {openSelectList ? (
        <>
          <Button variant="text" color="inherit" sx={{ alignItems: 'center', fontWeight: 500 }} onClick={handleBack}>
            <ArrowBackIcon sx={{ mr: 1 }} />
            <Typography>{translate('Back')}</Typography>
          </Button>
        </>
      ) : (
        <Stack flexDirection="row" alignItems="center">
          <Avatar sx={{ mr: 1 }}>{username?.charAt(0)?.toUpperCase()}</Avatar>
          <Typography>{username}</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default ProfileDrawerHeader;
