import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const EyeIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      opacity="0.4"
      d="M21.25 9.15018C18.94 5.52017 15.56 3.43018 12 3.43018C10.22 3.43018 8.49 3.95018 6.91 4.92018C5.33 5.90018 3.91 7.33017 2.75 9.15018C1.75 10.7202 1.75 13.2702 2.75 14.8402C5.06 18.4802 8.44 20.5602 12 20.5602C13.78 20.5602 15.51 20.0402 17.09 19.0702C18.67 18.0902 20.09 16.6602 21.25 14.8402C22.25 13.2802 22.25 10.7202 21.25 9.15018ZM12 16.0402C9.76 16.0402 7.96 14.2302 7.96 12.0002C7.96 9.77018 9.76 7.96018 12 7.96018C14.24 7.96018 16.04 9.77018 16.04 12.0002C16.04 14.2302 14.24 16.0402 12 16.0402Z"
      fill="inherit"
    />
    <path
      d="M12 9.14014C10.43 9.14014 9.15002 10.4201 9.15002 12.0001C9.15002 13.5701 10.43 14.8501 12 14.8501C13.57 14.8501 14.86 13.5701 14.86 12.0001C14.86 10.4301 13.57 9.14014 12 9.14014Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default EyeIcon;
