import { ReactNode } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { SelectChangeEvent } from '@mui/material';

import Select, { SelectProps } from 'components/common/Select';

interface FormSelectProps<ControlType extends FieldValues> extends Partial<SelectProps> {
  name: string;
  control: Control<ControlType>;
}

const FormSelect = <ControlType extends FieldValues>({
  name,
  control,
  onChange,
  ...props
}: FormSelectProps<ControlType>): JSX.Element => {
  const {
    fieldState: { error },
    field: { value, onChange: formOnChange },
  } = useController({
    name: name as Path<ControlType>,
    control,
  });

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode): void => {
    formOnChange(event, child);
    onChange?.(event, child);
  };

  return <Select {...props} onChange={handleChange} error={error?.message} value={value} />;
};

export default FormSelect;
