import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const UsersIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={22} height={22} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M16.0692 7.12242C16.005 7.11326 15.9409 7.11326 15.8767 7.12242C14.4559 7.07659 13.3284 5.91242 13.3284 4.48242C13.3284 3.02492 14.5109 1.83325 15.9775 1.83325C17.435 1.83325 18.6267 3.01575 18.6267 4.48242C18.6175 5.91242 17.49 7.07659 16.0692 7.12242Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M19.0574 13.4751C18.0307 14.1626 16.5916 14.4193 15.2624 14.2451C15.6107 13.4934 15.7941 12.6593 15.8032 11.7793C15.8032 10.8626 15.6016 9.99179 15.2166 9.23096C16.5732 9.04763 18.0124 9.30428 19.0482 9.99178C20.4966 10.9451 20.4966 12.5126 19.0574 13.4751Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M5.90344 7.12242C5.96761 7.11326 6.03178 7.11326 6.09595 7.12242C7.51678 7.07659 8.64428 5.91242 8.64428 4.48242C8.64428 3.02492 7.46178 1.83325 5.99511 1.83325C4.53761 1.83325 3.34595 3.01575 3.34595 4.48242C3.35511 5.91242 4.48261 7.07659 5.90344 7.12242Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M6.00427 11.7792C6.00427 12.6684 6.19677 13.5117 6.54511 14.2726C5.25261 14.4101 3.90511 14.1351 2.91511 13.4842C1.46677 12.5217 1.46677 10.9542 2.91511 9.99171C3.89594 9.33171 5.28011 9.06589 6.58177 9.21256C6.20594 9.98256 6.00427 10.8534 6.00427 11.7792Z"
      fill="inherit"
    />
    <path
      d="M11.1099 14.5475C11.0366 14.5383 10.9541 14.5383 10.8716 14.5475C9.1849 14.4925 7.8374 13.1083 7.8374 11.4033C7.8374 9.66167 9.2399 8.25 10.9907 8.25C12.7324 8.25 14.1441 9.66167 14.1441 11.4033C14.1441 13.1083 12.8057 14.4925 11.1099 14.5475Z"
      fill="inherit"
    />
    <path
      d="M8.1309 16.4449C6.74673 17.3708 6.74673 18.8924 8.1309 19.8091C9.70757 20.8633 12.2926 20.8633 13.8692 19.8091C15.2534 18.8833 15.2534 17.3616 13.8692 16.4449C12.3017 15.3908 9.71673 15.3908 8.1309 16.4449Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default UsersIcon;
