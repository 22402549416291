import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const PresentationChartIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M19.25 1.83325V12.9249C19.25 14.6666 18.3333 15.5833 16.5917 15.5833H5.40833C3.66666 15.5833 2.75 14.6666 2.75 12.9249V1.83325H19.25Z"
      fill="inherit"
    />
    <path
      d="M20.1663 2.52075H1.83301C1.45717 2.52075 1.14551 2.20909 1.14551 1.83325C1.14551 1.45742 1.45717 1.14575 1.83301 1.14575H20.1663C20.5422 1.14575 20.8538 1.45742 20.8538 1.83325C20.8538 2.20909 20.5422 2.52075 20.1663 2.52075Z"
      fill="inherit"
    />
    <path
      d="M15.2806 20.4782C15.1614 20.7166 14.9139 20.8541 14.6664 20.8541C14.5656 20.8541 14.4556 20.8266 14.3639 20.7808L10.9998 19.1032L7.63558 20.7808C7.54391 20.8266 7.43392 20.8541 7.33309 20.8541C7.08559 20.8541 6.83808 20.7166 6.71891 20.4782C6.54474 20.1299 6.68227 19.7174 7.0306 19.5524L10.3123 17.9116V15.5833H11.6873V17.9116L14.9689 19.5524C15.3172 19.7174 15.4548 20.1299 15.2806 20.4782Z"
      fill="inherit"
    />
    <path
      d="M6.87453 10.7708C6.68203 10.7708 6.48037 10.6883 6.34287 10.5233C6.09537 10.2299 6.14118 9.79911 6.43452 9.55161L9.32204 7.14077C9.58787 6.92077 9.92702 6.82911 10.2479 6.88411C10.5779 6.93911 10.862 7.14075 11.0362 7.43409L11.9987 9.03825L14.6845 6.80161C14.9778 6.55411 15.4087 6.59992 15.6562 6.89326C15.9037 7.18659 15.8579 7.61742 15.5645 7.86492L12.677 10.2758C12.4112 10.4958 12.072 10.5874 11.7512 10.5324C11.4212 10.4774 11.137 10.2758 10.9629 9.98245L10.0004 8.37828L7.31454 10.6149C7.1862 10.7158 7.03036 10.7708 6.87453 10.7708Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default PresentationChartIcon;
