import { useContext } from 'react';

import { AddNotification, ToasterContext } from 'components/common/Toaster/Toaster';

const useToaster = (): AddNotification => {
  const { addNotification } = useContext(ToasterContext);
  return addNotification;
};

export default useToaster;
