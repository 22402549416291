import { AgentPermissionsEnum, PermissionTemplateTypeEnum } from 'enums/accessTemplates';
import { PeriodEnum } from 'enums/dashboard';
import { TransferTypeEnum } from 'enums/entity';

export const STATUSES_OPTIONS = [
  { id: 'ACTIVE', name: 'Active' },
  { id: 'BLOCK', name: 'Blocked' },
];

export const VERIFICATION_STATUSES_OPTIONS = [
  { id: '1', name: 'Verified' },
  { id: '2', name: 'Not verified' },
  { id: '3', name: 'Awaiting verified' },
];

export const GENDER_OPTIONS = [
  { id: '1', name: 'Male' },
  { id: '2', name: 'Female' },
];

export const PREMIUM_OPTIONS = [
  { id: 'true', name: 'Premium' },
  { id: 'false', name: 'Basic' },
];

export const YES_OR_NO_OPTIONS = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
];

export const RATIOS = [
  {
    id: 1,
    name: '16:9',
  },
  {
    id: 2,
    name: '4:3',
  },
];

export const CHANNELS = [
  {
    id: 0,
    name: 'All',
  },
  {
    id: 1,
    name: 'Web',
  },
  {
    id: 2,
    name: 'Mobile',
  },
  {
    id: 3,
    name: 'Tablet',
  },
  {
    id: 4,
    name: 'Backend',
  },
];

export const TRANSFER_TYPE_OPTIONS = [
  {
    id: TransferTypeEnum.DEPOSIT,
    name: 'Deposit',
  },
  {
    id: TransferTypeEnum.WITHDRAW,
    name: 'Withdraw',
  },
];

export const ACCESS_TYPE_OPTIONS = [
  {
    id: PermissionTemplateTypeEnum.PUBLIC,
    name: 'Public',
    permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN],
  },
  {
    id: PermissionTemplateTypeEnum.PRIVATE,
    name: 'Private',
  },
  {
    id: PermissionTemplateTypeEnum.LAYERED,
    name: 'Layered',
    permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN],
  },
];

export const PERMISSIONS_OPTIONS = [
  {
    id: AgentPermissionsEnum.SUPER_ADMIN,
    name: 'Super Admin',
  },
  {
    id: AgentPermissionsEnum.ADMIN,
    name: 'Admin',
  },
  {
    id: AgentPermissionsEnum.AGENT_OWN_READ,
    name: 'Agent own read',
  },
  {
    id: AgentPermissionsEnum.AGENT_OWN_WRITE,
    name: 'Agent own write',
  },
  {
    id: AgentPermissionsEnum.AGENT_SUB_READ,
    name: 'Agent sub read',
  },
  {
    id: AgentPermissionsEnum.AGENT_SUB_WRITE,
    name: 'Agent sub write',
  },
  {
    id: AgentPermissionsEnum.PLAYER_OWN_READ,
    name: 'Player own read',
  },
  {
    id: AgentPermissionsEnum.PLAYER_OWN_WRITE,
    name: 'Player own write',
  },
  {
    id: AgentPermissionsEnum.PLAYER_SUB_READ,
    name: 'Player sub read',
  },
  {
    id: AgentPermissionsEnum.PLAYER_SUB_WRITE,
    name: 'Player sub write',
  },
  {
    id: AgentPermissionsEnum.ACCESS_TEMPLATE_WRITE,
    name: 'Access template write',
  },
];

export const periodicalOptions = [
  {
    id: PeriodEnum.TODAY,
    name: 'Today',
  },
  {
    id: PeriodEnum.YESTERDAY,
    name: 'Yesterday',
  },
  {
    id: PeriodEnum.WEEK,
    name: 'This week',
  },
  {
    id: PeriodEnum.LAST_WEEK,
    name: 'Last week',
  },
  {
    id: PeriodEnum.MONTH,
    name: 'This month',
  },
  {
    id: PeriodEnum.LAST_MONTH,
    name: 'Last month',
  },
];
