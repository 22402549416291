import { FC, lazy } from 'react';

import Redirect from 'components/common/Redirect';

const routes = [
  {
    path: '/signin',
    element: lazy(() => import('./SignIn')),
  },
  {
    path: '*',
    element: (() => <Redirect to="/signin" />) as FC,
  },
];

export default routes;
