export enum EntityEnum {
  PLAYER = 'player',
  AGENT = 'agent',
}

export enum TransferTypeEnum {
  DEPOSIT = 'deposit',
  WITHDRAW = 'withdraw',
}

export enum TrendingActivityEnum {
  UP = 'up',
  DOWN = 'down',
}

export enum FooterNavigationEnum {
  TRANSFER = 'transfer',
  CREATE = 'create',
  NOTIFICATION = 'notification',
  PROFILE = 'profile',
}

export enum AgentsAndPlayersEntityType {
  OWN = 'own',
  SUB = 'sub',
}

export enum FilterButtonStyleEnum {
  ICON = 'icon',
  BUTTON = 'button',
}

export enum FooterSelectMenuTypeEnum {
  PARTNER = 'Partner',
}
