import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const EmptyWalletIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M16.537 12.4209C16.152 12.7967 15.932 13.3376 15.987 13.9151C16.0695 14.9051 16.977 15.6292 17.967 15.6292H19.7087V16.7201C19.7087 18.6176 18.1595 20.1667 16.262 20.1667H5.73866C3.84116 20.1667 2.29199 18.6176 2.29199 16.7201V10.5509C2.29199 8.65342 3.84116 7.10425 5.73866 7.10425H16.262C18.1595 7.10425 19.7087 8.65342 19.7087 10.5509V11.8709H17.857C17.3437 11.8709 16.8762 12.0726 16.537 12.4209Z"
      fill="inherit"
    />
    <path
      d="M13.6128 3.62082V7.10415H5.73866C3.84116 7.10415 2.29199 8.65332 2.29199 10.5508V7.18667C2.29199 6.09584 2.96116 5.12413 3.97866 4.73913L11.257 1.98913C12.3937 1.56746 13.6128 2.40166 13.6128 3.62082Z"
      fill="inherit"
    />
    <path
      d="M20.6802 12.8058V14.6942C20.6802 15.1984 20.2769 15.6108 19.7635 15.6292H17.9669C16.9769 15.6292 16.0694 14.905 15.9869 13.915C15.9319 13.3375 16.1519 12.7967 16.5369 12.4208C16.876 12.0725 17.3435 11.8708 17.8569 11.8708H19.7635C20.2769 11.8892 20.6802 12.3016 20.6802 12.8058Z"
      fill="inherit"
    />
    <path
      d="M12.8337 11.6875H6.41699C6.04116 11.6875 5.72949 11.3758 5.72949 11C5.72949 10.6242 6.04116 10.3125 6.41699 10.3125H12.8337C13.2095 10.3125 13.5212 10.6242 13.5212 11C13.5212 11.3758 13.2095 11.6875 12.8337 11.6875Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default EmptyWalletIcon;
