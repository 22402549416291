import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AppLayout from 'pages/App';
import AuthLayout from 'pages/Auth';
import useAuth from 'store/auth';
import useMount from 'hooks/useMount';
import { DEFAULT_TITLE } from 'configs';

import useDidMount from '../hooks/useDidMount';
import { authStorage } from '../store/storage';

const Pages: FC = () => {
  const { pathname } = useLocation();

  const [authState, actions] = useAuth();

  useMount(() => {
    actions.getBranding();
  });

  useDidMount(() => {
    document.title = authStorage.get().branding.title || DEFAULT_TITLE;
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (authState?.data?.accessToken && authState?.data?.agent?.id) {
    return <AppLayout />;
  }

  return <AuthLayout />;
};

export default Pages;
