import { FC, useMemo, useState, MouseEvent } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Stack, Grid, ToggleButtonGroup, ToggleButton } from '@mui/material';
import Slide from '@mui/material/Slide';
import { EntityEnum } from 'enums/entity';
import { AgentPermissionsEnum } from 'enums/accessTemplates';

import { ENTITY_TYPES_INFO } from 'constant';
import Modal from 'components/common/Modal';
import AddIcon from 'components/common/icons/bulk/AddIcon';
import Form, { FormInput, FormSelect } from 'components/common/Form';
import { translate, TranslationKey } from 'utils/translate';
import PhoneField from 'components/common/Form/PhoneField';
import { createAgentSchema, createPlayerSchema } from 'configs/validationSchemes';
import useYupValidationResolver from 'hooks/useYupValidationResolver';
import useAuth from 'store/auth';
import { ApiErrorType, OptionType, PaginatedResponse } from 'types';
import useMount from 'hooks/useMount';
import fetcher from 'utils/fetcher';
import { POST_PLAYER, POST_AGENT, GET_PERMISSION_TEMPLATES_SELECT } from 'api/paths/constants';
import useScreen from 'hooks/useScreen';
import { AccessCreateAgentPermissions, AccessCreatePlayerPermissions } from 'utils/accessTemplates';
import useAgent from 'store/agent';
import usePlayer from 'store/player';
import { IAgentListDTO } from 'store/types/agent';
import { IPlayerListDTO } from 'store/types/player';
import useToaster from 'hooks/useToaster';

interface Props {
  onClose: () => void;
  permissions?: AgentPermissionsEnum[];
}

type CreatePlayerAgentType = {
  username: string;
  password: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  currencyCodes: string[];
  permissionTemplateId?: number;
};

const CreatePlayerAgentModal: FC<Props> = ({ onClose, permissions = [] }) => {
  const { isMobile } = useScreen();
  const [authState] = useAuth();
  const [, agentActions] = useAgent();
  const [, playerActions] = usePlayer();
  const [isLoading, setIsLoading] = useState(false);
  const [createType, setCreateType] = useState<EntityEnum | null>(null);
  const [permissionOptions, setPermissionOptions] = useState<OptionType[]>([]);
  const notify = useToaster();

  const resolver = useYupValidationResolver<CreatePlayerAgentType>(
    createType === EntityEnum.PLAYER ? createPlayerSchema : createAgentSchema,
  );
  const { control, reset, handleSubmit } = useForm<CreatePlayerAgentType>({
    resolver,
    defaultValues: {
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      currencyCodes: [],
      permissionTemplateId: undefined,
    },
  });

  useMount(() => {
    (async (): Promise<void> => {
      try {
        const response = await fetcher<PaginatedResponse<OptionType>>({
          method: 'GET',
          url: GET_PERMISSION_TEMPLATES_SELECT,
        });

        setPermissionOptions(response.data?.data ?? []);
      } catch (error) {
        console.error(error);
      }
    })();
  });

  const handleBack = (): void => {
    setCreateType(null);
    reset({
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      currencyCodes: [],
      permissionTemplateId: undefined,
    });
  };

  const handleChangeType = (event: MouseEvent<HTMLElement>, newAlignment: EntityEnum): void => {
    setCreateType(newAlignment);
  };

  const handleCreateSubmit = async (data: CreatePlayerAgentType): Promise<void> => {
    if (!createType) {
      return;
    }
    try {
      setIsLoading(true);
      const endpoint = createType === EntityEnum.PLAYER ? POST_PLAYER : POST_AGENT;
      const response = await fetcher<void, Partial<CreatePlayerAgentType>>({
        method: 'POST',
        url: endpoint,
        body: data,
      });
      createType === EntityEnum.PLAYER
        ? playerActions.addPlayer(response.data as unknown as IPlayerListDTO)
        : agentActions.addAgent(response.data as unknown as IAgentListDTO);
      setIsLoading(false);
      onClose();
      notify({
        message: 'Success',
        type: 'success',
      });
    } catch (error) {
      setIsLoading(false);
      notify({
        message: ((error as unknown as ApiErrorType)?.text as TranslationKey) || translate('Something went wrong'),
        type: 'error',
      });
    }
  };

  const onSave = async (): Promise<void> => {
    handleSubmit(handleCreateSubmit)();
  };

  const currencies = useMemo(() => {
    return (authState?.data?.wallets ?? []).map(
      (i) =>
        ({
          id: i.currencyCode,
          name: i.currencyCode,
        }) as OptionType,
    );
  }, [authState?.data?.wallets]);

  return (
    <Modal
      maxWidth={544}
      fullScreen={isMobile}
      saveText="Create"
      icon={<AddIcon />}
      title="Create New"
      isLoading={isLoading}
      showBack={!!createType}
      isSaveDisabled={!createType}
      onBack={handleBack}
      onClose={onClose}
      onSave={handleSubmit(onSave)}
    >
      <Box>
        <Slide direction="right" in={!createType} mountOnEnter unmountOnExit timeout={{ appear: 0, enter: 0, exit: 0 }}>
          <Stack alignItems="center">
            <ToggleButtonGroup
              exclusive
              color="primary"
              value={createType}
              onChange={handleChangeType}
              aria-label="Platform"
            >
              {AccessCreatePlayerPermissions.some((r) => permissions?.includes(r)) && (
                <ToggleButton value={EntityEnum.PLAYER}>
                  {translate(ENTITY_TYPES_INFO[EntityEnum.PLAYER].label)}
                </ToggleButton>
              )}
              {AccessCreateAgentPermissions.some((r) => permissions?.includes(r)) && (
                <ToggleButton value={EntityEnum.AGENT}>
                  {translate(ENTITY_TYPES_INFO[EntityEnum.AGENT].label)}
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </Stack>
        </Slide>
        <Slide
          direction="right"
          in={!!createType}
          mountOnEnter
          unmountOnExit
          timeout={{ appear: 300, enter: 300, exit: 0 }}
        >
          <Box>
            <Form onSubmit={handleSubmit(handleCreateSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormInput required label="Username" name="username" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput required label="Password" name="password" type="password" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput label="First Name" name="firstName" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput label="Last Name" name="lastName" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormInput label="Email" name="email" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneField label="Phone" name="phone" control={control} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormSelect
                    required
                    multiple
                    label="Currencies"
                    name="currencyCodes"
                    control={control}
                    options={currencies}
                  />
                </Grid>
                {createType === EntityEnum.AGENT && (
                  <Grid item xs={12} md={6}>
                    <FormSelect
                      required
                      label="Role"
                      name="permissionTemplateId"
                      control={control}
                      options={permissionOptions}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          </Box>
        </Slide>
      </Box>
    </Modal>
  );
};

export default CreatePlayerAgentModal;
