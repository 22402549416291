import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const HierarchySquareIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 22 22" {...props}>
    <path
      d="M14.8411 1.8335H7.1686C3.83194 1.8335 1.84277 3.82266 1.84277 7.15933V14.8318C1.84277 18.1685 3.83194 20.1577 7.1686 20.1577H14.8411C18.1778 20.1577 20.1669 18.1685 20.1669 14.8318V7.15933C20.1669 3.82266 18.1778 1.8335 14.8411 1.8335Z"
      fill="inherit"
      fillOpacity="0.4"
    />
    <path
      d="M15.0422 12.9892C14.483 11.3942 12.9797 10.3217 11.293 10.3217C11.2838 10.3217 11.2838 10.3217 11.2747 10.3217L9.38633 10.3309C9.38633 10.3309 9.38632 10.3309 9.37716 10.3309C8.68049 10.3309 8.07549 9.86339 7.89216 9.19423C8.70799 8.93756 9.30386 8.17673 9.30386 7.2784C9.30386 6.16923 8.39635 5.26172 7.28719 5.26172C6.17802 5.26172 5.27051 6.16923 5.27051 7.2784C5.27051 8.09423 5.7655 8.80006 6.46216 9.11173V13.0901C5.7655 13.3651 5.27051 14.0434 5.27051 14.8317C5.27051 15.8676 6.11383 16.7109 7.14966 16.7109C8.1855 16.7109 9.02882 15.8676 9.02882 14.8317C9.02882 14.0342 8.53383 13.3651 7.83716 13.0901V11.2384C8.28633 11.5226 8.81799 11.6876 9.37716 11.6876H9.38633L11.2747 11.6784C12.3563 11.6417 13.3189 12.3384 13.7039 13.3467C13.2547 13.6951 12.9614 14.2267 12.9614 14.8317C12.9614 15.8676 13.8047 16.7109 14.8405 16.7109C15.8763 16.7109 16.7197 15.8676 16.7197 14.8317C16.7288 13.8784 15.9863 13.0901 15.0422 12.9892Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default HierarchySquareIcon;
