import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const TransferIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      d="M17.7499 7.04997C17.5099 7.00997 17.2599 6.99998 16.9999 6.99998H6.99994C6.71994 6.99998 6.44994 7.01998 6.18994 7.05998C6.32994 6.77998 6.52994 6.52001 6.76994 6.28001L10.0199 3.02C11.3899 1.66 13.6099 1.66 14.9799 3.02L16.7299 4.78996C17.3699 5.41996 17.7099 6.21997 17.7499 7.04997Z"
      fill="inherit"
    />
    <path
      d="M5 15C2.79 15 1 16.79 1 19C1 19.75 1.21 20.46 1.58 21.06C2.27 22.22 3.54 23 5 23C6.46 23 7.73 22.22 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15ZM6.49 19.73H5.75V20.51C5.75 20.92 5.41 21.26 5 21.26C4.59 21.26 4.25 20.92 4.25 20.51V19.73H3.51C3.1 19.73 2.76 19.39 2.76 18.98C2.76 18.57 3.1 18.23 3.51 18.23H4.25V17.52C4.25 17.11 4.59 16.77 5 16.77C5.41 16.77 5.75 17.11 5.75 17.52V18.23H6.49C6.9 18.23 7.24 18.57 7.24 18.98C7.24 19.39 6.91 19.73 6.49 19.73Z"
      fill="inherit"
    />
    <path
      opacity="0.4"
      d="M22 12V17C22 20 20 22 17 22H7.63C7.94 21.74 8.21 21.42 8.42 21.06C8.79 20.46 9 19.75 9 19C9 16.79 7.21 15 5 15C3.8 15 2.73 15.53 2 16.36V12C2 9.28 3.64 7.38 6.19 7.06C6.45 7.02 6.72 7 7 7H17C17.26 7 17.51 7.00999 17.75 7.04999C20.33 7.34999 22 9.26 22 12Z"
      fill="inherit"
    />
    <path d="M22 12.5H19C17.9 12.5 17 13.4 17 14.5C17 15.6 17.9 16.5 19 16.5H22" fill="inherit" />
  </SvgIcon>
);

export default TransferIcon;
