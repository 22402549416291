import { BrowserRouter } from 'react-router-dom';
import { FC } from 'react';

import Pages from 'pages';
import ThemeProvider from 'theme';
import StoreProvider from 'store/StoreProvider';
import ToasterProvider from 'components/common/Toaster/Toaster';
import { DEFAULT_TITLE } from 'configs';
import { authStorage } from 'store/storage';

const App: FC = () => {
  document.title = authStorage.get().branding.title || DEFAULT_TITLE;
  return (
    <BrowserRouter>
      <StoreProvider>
        <ThemeProvider>
          <ToasterProvider>
            <Pages />
          </ToasterProvider>
        </ThemeProvider>
      </StoreProvider>
    </BrowserRouter>
  );
};

export default App;
