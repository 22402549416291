export enum ActivitySectionsEnum {
  REAL_TIME = 'realTime',
  PERIODICAL = 'periodical',
  BALANCE = 'balance',
  TRANSACTIONS = 'transactions',
}

export enum ActivityTypeEnum {
  AGENT_COUNT = 'agentCount',
  AGENT_REAL_BALANCE = 'agentRealBalance',
  PLAYER_COUNT = 'playerCount',
  PLAYER_REAL_BALANCE = 'playerRealBalance',
  PLAYER_BONUS_BALANCE = 'playerBonusBalance',
  REAL_WIN_AMOUNT = 'realWinAmount',
  REAL_WIN_PERCENTAGE = 'realWinPercentage',
  BONUS_WIN_AMOUNT = 'bonusWinAmount',
  REAL_BET_AMOUNT = 'realBetAmount',
  REAL_BET_PERCENTAGE = 'realBetPercentage',
  BONUS_BET_AMOUNT = 'bonusBetAmount',
  REAL_DEPOSIT_AMOUNT = 'realDepositAmount',
  REAL_DEPOSIT_PERCENTAGE = 'realDepositPercentage',
  REAL_DEPOSIT_ADJUSTMENT = 'realDepositAdjustment',
  REAL_WITHDRAW_AMOUNT = 'realWithdrawAmount',
  REAL_WITHDRAW_PERCENTAGE = 'realWithdrawPercentage',
  REAL_WITHDRAW_ADJUSTMENT = 'realWithdrawAdjustment',
  GGR = 'ggr',
}

export enum PeriodEnum {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  WEEK = 'week',
  LAST_WEEK = 'lastWeek',
  MONTH = 'month',
  LAST_MONTH = 'lastMonth',
}
