import { FC, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { AgentPermissionsEnum } from 'enums/accessTemplates';

import TransferIcon from 'components/common/icons/bulk/Transfer';
import AddIcon from 'components/common/icons/bulk/AddIcon';
import { AccessCreatePermissions } from 'utils/accessTemplates';

import CreatePlayerAgentModal from '../MainModals/CreatePlayerAgentModal';
import TransferModal from '../MainModals/TransferModal';

type Props = {
  permissions: AgentPermissionsEnum[];
};

const PopupMenu: FC<Props> = ({ permissions }) => {
  const [createOpen, setCreateOpen] = useState(false);
  const [transferOpen, setTransferOpen] = useState(false);

  const openCreatePopup = (): void => setCreateOpen(true);
  const openTransferPopup = (): void => setTransferOpen(true);
  return (
    <>
      <Stack direction="row" spacing={0.5} mx={1}>
        {AccessCreatePermissions.some((r) => permissions.includes(r)) && (
          <IconButton onClick={openCreatePopup}>
            <AddIcon />
          </IconButton>
        )}
        <IconButton onClick={openTransferPopup}>
          <TransferIcon />
        </IconButton>
      </Stack>
      {createOpen && <CreatePlayerAgentModal permissions={permissions} onClose={(): void => setCreateOpen(false)} />}
      {transferOpen && <TransferModal onClose={(): void => setTransferOpen(false)} />}
    </>
  );
};

export default PopupMenu;
