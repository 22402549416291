import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const ArrowDownIcon: FC<SvgIconProps> = (props): JSX.Element => (
  <SvgIcon width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.41438 9.53148C6.67313 9.20803 7.1451 9.15559 7.46855 9.41435L12 13.0395L16.5315 9.41435C16.855 9.15559 17.3269 9.20803 17.5857 9.53148C17.8444 9.85492 17.792 10.3269 17.4685 10.5856L12.4685 14.5856C12.1946 14.8048 11.8054 14.8048 11.5315 14.5856L6.53151 10.5856C6.20806 10.3269 6.15562 9.85492 6.41438 9.53148Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default ArrowDownIcon;
