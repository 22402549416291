import { FC } from 'react';
import { useTheme } from '@mui/material';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { WalletType } from 'types';
import { translate } from 'utils/translate';
import valueToPrecision from 'utils/valueToPrecision';

type Props = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  wallets: WalletType[];
};

const BalanceBox: FC<Props> = ({ anchorEl, onClose, wallets }) => {
  const theme = useTheme();

  const id = anchorEl ? 'simple-popover' : undefined;

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {wallets.map((item) => (
          <ListItem key={item.currencyCode || item.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemText
              primary={`${translate('Balance')}:`}
              sx={{ mr: 2, flexGrow: 1, '& .MuiTypography-root': { fontSize: theme.typography.body2.fontSize } }}
            />
            <ListItemText
              primary={`${item.currencyCode} ${valueToPrecision(item.balance)}`}
              sx={{ textAlign: 'right', '& .MuiTypography-root': { fontSize: theme.typography.body2.fontSize } }}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
};

export default BalanceBox;
