import { FC, lazy } from 'react';
import { AgentPermissionsEnum } from 'enums/accessTemplates';

import { IRoute } from 'pages/utils';
import BlankOutlet from 'components/common/BlankOutlet';
import Redirect from 'components/common/Redirect';
import PlayerCheckPermission from 'pages/App/Players/PlayersCheckPermission';

const routes: IRoute[] = [
  {
    path: '/partners',
    element: lazy(() => import('./Partners')),
    permissions: [AgentPermissionsEnum.SUPER_ADMIN],
  },
  {
    path: '/dashboard',
    element: lazy(() => import('./Dashboard')),
  },
  {
    path: '/partners/:partnerId',
    element: lazy(() => import('./Partners/Partner')),
    permissions: [AgentPermissionsEnum.SUPER_ADMIN],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'main', element: lazy(() => import('./Partners/Partner/Main')) },
      { path: 'currency', element: lazy(() => import('./Partners/Partner/Currencies')) },
    ],
  },
  {
    path: '/agents',
    element: lazy(() => import('./Agents')),
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.AGENT_OWN_READ,
      AgentPermissionsEnum.AGENT_OWN_WRITE,
    ],
    routes: [
      { path: '', element: (() => <Redirect to="own" />) as FC },
      {
        path: 'own',
        element: lazy(() => import('./Agents/Own')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_OWN_READ,
          AgentPermissionsEnum.AGENT_OWN_WRITE,
        ],
      },
      {
        path: 'sub',
        element: lazy(() => import('./Agents/Sub')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_SUB_READ,
          AgentPermissionsEnum.AGENT_SUB_WRITE,
        ],
      },
    ],
  },
  {
    path: '/players',
    element: lazy(() => import('./Players')),
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.PLAYER_OWN_READ,
      AgentPermissionsEnum.PLAYER_OWN_WRITE,
      AgentPermissionsEnum.PLAYER_SUB_READ,
      AgentPermissionsEnum.PLAYER_SUB_WRITE,
    ],
    routes: [
      { path: '', element: (() => <PlayerCheckPermission />) as FC },
      {
        path: 'own',
        element: lazy(() => import('./Players/Own')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_OWN_READ,
          AgentPermissionsEnum.PLAYER_OWN_WRITE,
        ],
      },
      {
        path: 'sub',
        element: lazy(() => import('./Players/Sub')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_SUB_READ,
          AgentPermissionsEnum.PLAYER_SUB_WRITE,
        ],
      },
    ],
  },
  {
    path: '/agents/:agentId',
    element: lazy(() => import('./Agents/Agent')),
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Agents/Agent/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Agents/Agent/Main')) },
      {
        path: 'transactions',
        element: BlankOutlet,
        routes: [
          {
            path: '',
            element: (() => <Redirect to="from-parent" />) as FC,
          },
          { path: 'from-parent', element: lazy(() => import('./Agents/Agent/Transactions/FromParent')) },
          { path: 'to-agents', element: lazy(() => import('./Agents/Agent/Transactions/ToSubAgents')) },
          { path: 'to-players', element: lazy(() => import('./Agents/Agent/Transactions/ToSubPlayers')) },
        ],
      },
    ],
  },
  {
    path: '/agents-tree',
    element: lazy(() => import('./AgentsTree')),
    permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN, AgentPermissionsEnum.AGENT_SUB_READ],
  },
  {
    path: '/players/:playerId',
    element: lazy(() => import('./Players/Player')),
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.PLAYER_OWN_READ,
      AgentPermissionsEnum.PLAYER_OWN_WRITE,
      AgentPermissionsEnum.PLAYER_SUB_READ,
      AgentPermissionsEnum.PLAYER_SUB_WRITE,
    ],
    routes: [
      {
        path: '',
        element: (() => <Redirect to="main" />) as FC,
      },
      { path: 'dashboard', element: lazy(() => import('./Players/Player/Dashboard')) },
      { path: 'main', element: lazy(() => import('./Players/Player/Main')) },
      { path: 'bets', element: lazy(() => import('./Players/Player/BetHistory')) },
      { path: 'walletHistory', element: lazy(() => import('./Players/Player/WalletHistory')) },
      { path: 'transactions', element: lazy(() => import('./Players/Player/Transactions')) },
    ],
  },
  {
    path: '/reports',
    element: BlankOutlet,
    permissions: [
      AgentPermissionsEnum.SUPER_ADMIN,
      AgentPermissionsEnum.ADMIN,
      AgentPermissionsEnum.AGENT_OWN_READ,
      AgentPermissionsEnum.AGENT_SUB_READ,
      AgentPermissionsEnum.PLAYER_OWN_READ,
      AgentPermissionsEnum.PLAYER_SUB_READ,
    ],
    routes: [
      { path: '', element: (() => <Redirect to="player" />) as FC },
      {
        path: 'category',
        element: lazy(() => import('./Reports/Category')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.PLAYER_OWN_READ,
          AgentPermissionsEnum.PLAYER_SUB_READ,
        ],
      },
      {
        path: 'provider',
        element: lazy(() => import('./Reports/Provider')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_OWN_READ,
          AgentPermissionsEnum.AGENT_SUB_READ,
        ],
      },
      {
        path: 'agent',
        element: lazy(() => import('./Reports/Agent')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_OWN_READ,
          AgentPermissionsEnum.AGENT_SUB_READ,
        ],
      },
      {
        path: 'player',
        element: lazy(() => import('./Reports/Player')),
        permissions: [
          AgentPermissionsEnum.SUPER_ADMIN,
          AgentPermissionsEnum.ADMIN,
          AgentPermissionsEnum.AGENT_OWN_READ,
          AgentPermissionsEnum.AGENT_SUB_READ,
        ],
      },
    ],
  },
  {
    path: '/settings',
    element: BlankOutlet,
    routes: [
      { path: '', element: (() => <Redirect to="currencies" />) as FC },
      {
        path: 'currencies',
        element: lazy(() => import('./Currencies')),
        permissions: [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN],
      },
      { path: 'branding', element: BlankOutlet },
      { path: 'access-templates', element: lazy(() => import('./AccessTemplates')) },
      { path: 'configurations', element: BlankOutlet },
    ],
  },
  {
    path: '*',
    element: (() => <Redirect to="/dashboard" />) as FC,
  },
];

export default routes;
