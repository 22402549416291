const style = {
  lg: {
    root: {
      width: 'calc(100%)',
      height: 65,
      pl: 5,
      pr: 2,
      display: 'flex',
      alignItems: 'center',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'custom.neutrals.scale.100',
      backgroundColor: 'common.white',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
    },
  },
  sx: {
    root: {
      width: 'calc(100%)',
      height: 65,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'custom.neutrals.scale.100',
      backgroundColor: 'white',
      position: 'fixed',
      top: 0,
      left: 0,
      zIndex: 1000,
    },
  },
};

export default style;
