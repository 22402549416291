export enum PermissionTemplateTypeEnum {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
  LAYERED = 'LAYERED',
}

export enum AgentPermissionsEnum {
  SUPER_ADMIN = 'agent_super_admin',
  ADMIN = 'agent_admin',
  DASHBOARD = 'agent_dashboard',
  AGENT_OWN_READ = 'agent_agent_own_read',
  AGENT_OWN_WRITE = 'agent_agent_own_write',
  AGENT_SUB_READ = 'agent_agent_sub_read',
  AGENT_SUB_WRITE = 'agent_agent_sub_write',
  PLAYER_OWN_READ = 'agent_player_own_read',
  PLAYER_OWN_WRITE = 'agent_player_own_write',
  PLAYER_SUB_READ = 'agent_player_sub_read',
  PLAYER_SUB_WRITE = 'agent_player_sub_write',
  ACCESS_TEMPLATE_WRITE = 'agent_access_template_write',
}
