import { EMPTY_OBJECT } from 'constant';

/**
 * Parses a JSON string and returns a JavaScript object.
 * @param data The JSON string to parse.
 * @returns The parsed JavaScript object, or an empty object if the input is not a valid JSON string.
 */
const parseJson = <T>(data?: string): T => {
  if (!data) {
    return EMPTY_OBJECT as T;
  }

  try {
    return JSON.parse(data);
  } catch {
    return EMPTY_OBJECT as T;
  }
};

export default parseJson;
