import { FC, ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AgentPermissionsEnum } from 'enums/accessTemplates';

export interface IRoute {
  path?: string;
  element: FC;
  routes?: IRoute[];
  permissions?: AgentPermissionsEnum[];
}

interface SwitchRouterProps {
  routes: IRoute[];
  existPermissions?: AgentPermissionsEnum[];
}

const renderRoute = (route: IRoute, existPermissions: AgentPermissionsEnum[] = []): ReactNode => {
  const { path, routes, element: Element, permissions } = route;

  if (permissions && !permissions.some((r) => existPermissions?.includes(r))) {
    console.warn('return');
  }

  return (
    <Route key={path} path={path} element={<Element />}>
      {Array.isArray(routes) && routes.map((i) => renderRoute(i, existPermissions))}
    </Route>
  );
};

export const SwitchRouter: FC<SwitchRouterProps> = ({ routes, existPermissions = [] }) => (
  <Routes>{routes.map((i) => renderRoute(i, existPermissions))}</Routes>
);

export default SwitchRouter;
