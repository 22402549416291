import { FC, ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from './index';

interface StoreProviderProps {
  children?: ReactNode;
}

const StoreProvider: FC<StoreProviderProps> = ({ children }) => <Provider store={store}>{children}</Provider>;

export default StoreProvider;
