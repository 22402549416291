import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import useMount from 'hooks/useMount';

const Redirect: FC<{ to: string; minimalTimeout?: number }> = ({ to, minimalTimeout = 200 }) => {
  const navigate = useNavigate();

  useMount(() => {
    const timerId = setTimeout(() => navigate(to), minimalTimeout);
    return () => clearTimeout(timerId);
  });

  return (
    <Box display="flex" height="100vh" justifyContent="center" alignItems="center">
      <CircularProgress size={32} />
    </Box>
  );
};

export default Redirect;
