import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

import { dayjsify, endOfDay, getMilliseconds, isValidDate, startOfDay } from 'utils/date';
import { DATE_FORMAT, FULL_DATE_TIME_FORMAT } from 'configs';

interface FormDateRangePickerProps<TFieldValues extends FieldValues = FieldValues> {
  label?: string;
  withTime?: boolean;
  required?: boolean;
  disabled?: boolean;
  maxDate?: dayjs.Dayjs;
  minDate?: dayjs.Dayjs;
  control: Control<TFieldValues>;
  rangeEndName: Path<TFieldValues>;
  rangeStartName: Path<TFieldValues>;
}

function mirror<T>(anyThing: T): T {
  return anyThing;
}

function FormDateRangePicker<TFieldValues extends FieldValues = FieldValues>({
  label,
  withTime = false,
  control,
  maxDate,
  minDate,
  required,
  disabled,
  rangeEndName,
  rangeStartName,
}: FormDateRangePickerProps<TFieldValues>): JSX.Element | null {
  const DateComponent = withTime ? DateTimePicker : DatePicker;

  const {
    field: { value: startValue, onChange: onStartChange },
    formState: { errors: startErrors },
  } = useController({
    control,
    name: rangeStartName,
  });
  const {
    field: { value: endValue, onChange: onEndChange },
    formState: { errors: endErrors },
  } = useController({
    control,
    name: rangeEndName,
  });

  const handleChangeStartDate = useCallback(
    (value: dayjs.Dayjs | null): void => {
      const dateFrom = isValidDate(value)
        ? withTime
          ? getMilliseconds(value)
          : getMilliseconds(startOfDay(value))
        : null;
      dateFrom && onStartChange(dateFrom);
    },
    [onStartChange, withTime],
  );

  const handleChangeEndDate = useCallback(
    (value: dayjs.Dayjs | null): void => {
      const dateTo = isValidDate(value) ? (withTime ? getMilliseconds(value) : getMilliseconds(endOfDay(value))) : null;
      dateTo && onEndChange(dateTo);
    },
    [onEndChange, withTime],
  );

  const ampmAttrObj = useMemo(() => (withTime ? { ampm: false } : {}), [withTime]);

  const fieldErrors = useMemo(
    () =>
      Object.fromEntries(
        Object.entries({ ...startErrors, ...endErrors })
          .filter(([key]) => key.includes(rangeEndName) || key.includes(rangeStartName))
          .map(([key, itemValue]) => [
            key.replace(`${rangeEndName}.`, '').replace(`${rangeStartName}.`, ''),
            itemValue,
          ]),
      ),
    [startErrors, endErrors, rangeEndName, rangeStartName],
  );

  if (!control || !rangeEndName || !rangeStartName) {
    return null;
  }

  return (
    <Box width="100%">
      {label && (
        <Typography
          mb={0.5}
          fontSize={14}
          fontWeight={500}
          lineHeight="20px"
          {...(disabled && { color: 'custom.grayscale.scale.50' })}
        >
          {label} {required && '*'}
        </Typography>
      )}
      <Stack flexDirection="row">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateComponent<dayjs.Dayjs>
            {...ampmAttrObj}
            disabled={disabled}
            format={withTime ? FULL_DATE_TIME_FORMAT : DATE_FORMAT}
            value={startValue ? dayjsify(startValue) : null}
            minDate={minDate ? minDate : undefined}
            maxDate={endValue ? dayjsify(endValue) : undefined}
            onChange={handleChangeStartDate}
            dayOfWeekFormatter={mirror}
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            }}
          />
          <DateComponent<dayjs.Dayjs>
            {...ampmAttrObj}
            disabled={disabled}
            format={withTime ? FULL_DATE_TIME_FORMAT : DATE_FORMAT}
            value={endValue ? dayjsify(endValue) : null}
            minDate={startValue ? dayjsify(startValue) : undefined}
            maxDate={maxDate ? maxDate : undefined}
            onChange={handleChangeEndDate}
            dayOfWeekFormatter={mirror}
            sx={{
              flexGrow: 1,
              '& .MuiInputBase-root, & .MuiOutlinedInput-notchedOutline': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              },
            }}
          />
        </LocalizationProvider>
      </Stack>
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        {fieldErrors?.startDate && (
          <Typography variant="caption" color="error">
            {String(fieldErrors.startDate?.message)}
          </Typography>
        )}
        {fieldErrors?.endDate && (
          <Typography variant="caption" color="error">
            {String(fieldErrors.endDate?.message)}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default FormDateRangePicker;
