import { FC } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  selectedId: number | null;
  list: { id: number; name: string }[];
  onClick: (id: number) => void;
};

const SelectListBox: FC<Props> = ({ selectedId, list, onClick }) => {
  const handleClick = (id: number): void => onClick(id);
  return (
    <Box>
      <List
        sx={{
          width: '100%',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(80vh - 72px)',
        }}
      >
        {list.map((item) => (
          <ListItem disablePadding key={`item-${item.id}`} onClick={(): void => handleClick(item.id)}>
            <ListItemButton>
              <ListItemText primary={item.name} />
              {selectedId === item.id && (
                <ListItemIcon sx={{ color: 'custom.primary.scale.500', minWidth: 20 }}>
                  <CheckIcon />
                </ListItemIcon>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SelectListBox;
