import noop from 'lodash/noop';
import { FC, createContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { Stack, Box, Typography, IconButton, AlertColor } from '@mui/material';

import RemoveIcon from 'components/common/icons/Remove';
import RemoveCircleIcon from 'components/common/icons/bulk/RemoveCircle';
import { TranslationItem, TranslationKey, translate } from 'utils/translate';
import { EMPTY_ARRAY } from 'constant';

import sx from './styles';

interface ToasterProviderProps {
  children?: ReactNode;
}

type NotificationData = {
  id?: number;
  title?: TranslationKey;
  message: TranslationItem;
  deleteDelay?: number;
  type?: AlertColor;
};

interface ToasterProps {
  notification: NotificationData;
  onClose: (id?: number) => void;
}

export type AddNotification = (note: NotificationData) => void;

const ToasterContext = createContext<{
  notifications: NotificationData[];
  addNotification: AddNotification;
}>({ notifications: EMPTY_ARRAY, addNotification: noop });

const TIMEOUT = 3600;
const SHOW_COUNT = 5;

const STATUS_COLORS = {
  error: 'custom.red.scale.500',
  info: 'custom.primary.scale.500',
  success: 'custom.green.scale.500',
  warning: 'custom.yellow.scale.500',
};

const Toaster: FC<ToasterProps> = ({ notification: { message, title, ...notification }, onClose }) => {
  const color = STATUS_COLORS[notification?.type || 'info'];

  return (
    <Box sx={sx.toasterRoot({ color })}>
      <Box sx={sx.toasterContent}>
        <Box sx={sx.toasterIcon({ color })}>
          <RemoveCircleIcon />
        </Box>
        <Typography sx={sx.toasterText({ color })}>
          {!!title && translate(title)}
          {translate(message)}
        </Typography>
        <IconButton
          size="small"
          color="inherit"
          aria-label="close"
          sx={{ '& svg': { fontSize: 30 } }}
          onClick={(): void => onClose(notification?.id)}
        >
          <RemoveIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const ToasterProvider: FC<ToasterProviderProps> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationData[]>(EMPTY_ARRAY);

  const remove = useCallback((noteId?: number) => {
    setNotifications((prev) => prev.filter(({ id }) => id !== noteId));
  }, []);

  const addNotification = useCallback(
    (note: NotificationData) => {
      const noteId = Date.now();
      setNotifications((prev: NotificationData[]): NotificationData[] => {
        if (prev[prev.length - 1]?.message !== note.message) {
          const next = [
            ...prev,
            {
              id: noteId,
              type: 'success' as AlertColor,
              ...note,
            },
          ];
          if (next.length >= SHOW_COUNT) {
            // eslint-disable-next-line
            const [_, ...rest] = next;
            return rest;
          }

          return next;
        }
        return prev;
      });

      setTimeout(() => remove(noteId), note.deleteDelay || TIMEOUT);
    },
    [remove],
  );

  const memoizedValue = useMemo(() => ({ notifications, addNotification }), [notifications, addNotification]);

  return (
    <ToasterContext.Provider value={memoizedValue}>
      <Stack sx={sx.notificationsList} spacing={2}>
        {notifications?.length > 0 &&
          notifications.map((note) => <Toaster key={note.id} notification={note} onClose={remove} />)}
      </Stack>
      {children}
    </ToasterContext.Provider>
  );
};

export { ToasterContext };
export default ToasterProvider;
