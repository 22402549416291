import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { BROWSER_DATE_FORMAT, BROWSER_DATE_TIME_FORMAT, DATE_FORMAT, TIMEZONE_DIFFERENCE } from 'configs';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export const dateOfBirth = {
  min: dayjs().subtract(70, 'year').format(BROWSER_DATE_FORMAT),
  max: dayjs().subtract(21, 'year').format(BROWSER_DATE_FORMAT),
};

export const getToday = (): string => dayjs().format(BROWSER_DATE_FORMAT);
export const getTomorrow = (): string => dayjs().add(1, 'day').format(BROWSER_DATE_FORMAT);

type DateType = string | number | dayjs.Dayjs | Date;

const formatter = (date?: DateType, format?: string): string =>
  dayjs(date && (typeof date === 'number' ? date : String(date).split('.').shift())).format(format);

export const browserDateFormatter = (date: DateType): string => formatter(date, BROWSER_DATE_FORMAT);

export const dateFormatter = (date: DateType, format: string = DATE_FORMAT): string => formatter(date, format);

export const dayjsify = (date: DateType): dayjs.Dayjs => dayjs(typeof date === 'string' ? Number(date) : date);

export const getMilliseconds = (date: DateType): number => dayjs(date).valueOf();

export const toISOString = (date: DateType): string => new Date(browserDateFormatter(date) as string).toISOString();

export const isValidDate = (value?: DateType | null, format: string = BROWSER_DATE_TIME_FORMAT): value is DateType =>
  Boolean(value) && dayjs(value, format).isValid();

export const isoFormatter = (value: DateType): string => dayjs(value, BROWSER_DATE_TIME_FORMAT).format(DATE_FORMAT);

export const toIso = (date?: DateType): string =>
  isValidDate(date, DATE_FORMAT) ? dayjs(date, DATE_FORMAT).format(BROWSER_DATE_TIME_FORMAT) : '';

export const endOfDay = (date: DateType): dayjs.Dayjs => dayjs(date).endOf('date'); // .utcOffset(0)
export const startOfDay = (date: DateType): dayjs.Dayjs => dayjs(date).startOf('date'); // .utcOffset(0)

class UTCDateManipulator {
  plus(date: DateType): string {
    if (isValidDate(date)) {
      return toIso(dayjs(date).add(TIMEZONE_DIFFERENCE, 'h'));
    }
    return '';
  }

  minus(date: DateType): string {
    if (isValidDate(date)) {
      return toIso(dayjs(date).utcOffset(0).subtract(TIMEZONE_DIFFERENCE, 'h'));
    }
    return '';
  }
}

export const dateManipulator = new UTCDateManipulator();
