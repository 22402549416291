export const CREATE_SYSTEM_AGGREGATOR_AVAILABLE_PUT = (id: number): string => `system/aggregator/available/${id}`;
export const CREATE_SYSTEM_GAMES_PUT = (id?: number): string => `system/game/${id}`;
export const CREATE_PARTNER_PAYMENT_METHOD_GET = (id: number): string => `payment/methods/partner/${id}`;
export const CREATE_PARTNER_CURRENCY_GET = (id: number | string): string => `agent/partner/${id}/currency`;
export const CREATE_WEBSITE_DOMAINS_GET = (id: number): string => `domains?websiteId=${id}`;
export const CREATE_WEBSITE_SKINS_GET = (id: number): string => `website/${id}/skins`;
export const CREATE_WEBSITE_LANGUAGES_GET = (id: number): string => `common/partner/language/?websiteId=${id}`;
export const CREATE_SYSTEM_PROVIDER_AVAILABLE_PUT = (id: number): string => `system/providera/available/${id}`;
export const CREATE_SYSTEM_PAYMENT_AGGREGATOR_AVAILABLE_PUT = (id?: number): string =>
  `payment/system/aggregators/available/${id}`;
export const CREATE_SYSTEM_PAYMENT_METHOD_AVAILABLE_PUT = (id?: number): string =>
  `payment/system/methods/available/${id}`;
export const CREATE_SYSTEM_PAYMENT_PROVIDER_PUT = (id?: number): string => `payment/system/providers/${id}`;
export const CREATE_SYSTEM_PAYMENT_AVAILABLE_PUT = (id?: number): string => `payment/system/providers/available/${id}`;
export const CREATE_SYSTEM_PAYMENT_CATEGORIES_PUT = (id?: number): string => `payment/system/categories/${id}`;
export const CREATE_SYSTEM_PAYMENT_METHOD_PUT = (id?: number): string => `payment/system/methods/${id}`;
export const CREATE_WEBSITE_TOURNAMENTS_PUT = (id?: number): string => `tournaments/${id}`;
export const CREATE_WEBSITE_CATEGORY_GET = (id?: number): string => `websites/${id}/currency`;
export const CREATE_WEBSITE_CATEGORY_PUT = (id?: number): string => `website/categories/${id}`;
export const CREATE_WEBSITE_CATEGORY_AVAILABLE_PUT = (id?: number): string => `website/categories/enable/${id}`;
export const CREATE_WEBSITE_GAME_AVAILABLE_PUT = (id?: number): string => `website/game/enable/${id}`;
export const CREATE_WEBSITE_TOURNAMENT_TEMPLATES_PUT = (id?: number): string => `tournament-templates/${id}`;
export const CREATE_WEBSITE_PLAYERS_GROUP_PUT = (id?: number): string => `riskGroup/${id}`;
export const CREATE_WEBSITE_TAG_PUT = (id?: number): string => `website/tags/${id}`;
export const CREATE_WEBSITE_TAG_VISIBLE_PUT = (id?: number): string => `website/tags/visible/${id}`;
export const CREATE_CASINO_LIMIT_PUT = (id?: number): string => `casino/limits/${id}`;
export const CREATE_CASINO_LIMIT_DELETE = (id?: number): string => `casino/limits/${id}`;

//Account
export const CREATE_AGENT_PASSWORD_PUT = (id: number): string => `agent/${id}/password`;

//Agent
export const CREATE_AGENT_DEPOSIT_PUT = (id: number | string): string => `agent/deposit/${id}`;
export const CREATE_AGENT_WITHDRAW_PUT = (id: number | string): string => `agent/withdraw/${id}`;

//Player
export const CREATE_PLAYER_DEPOSIT_PUT = (id: number | string): string => `agent/player/deposit/${id}`;
export const CREATE_PLAYER_WITHDRAW_PUT = (id: number | string): string => `agent/player/withdraw/${id}`;
export const CREATE_PLAYER_GET = (id: number): string => `agents/player/info/${id}`;
export const CREATE_PUT_PLAYER_WITHDRAW = (id: number): string => `agent/player/withdraw/${id}`;
export const CREATE_PUT_PLAYER_DEPOSIT = (id: number): string => `agent/player/deposit/${id}`;
export const CREATE_PLAYER_PUT = (id: number): string => `agent/players/${id}`;
export const CREATE_AGENT_PUT = (id: number): string => `agents/${id}`;
export const CREATE_AGENT_CHANGE_PASSWORD_PUT = (id: number): string => `agent/sub/password/${id}`;

export const CREATE_AGENT_GET = (id: number): string => `agents/info/${id}`;
// export const CREATE_PUT_PLAYER_WITHDRAW = (id: number): string => `agent/player/withdraw/${id}`;
// export const CREATE_PUT_PLAYER_DEPOSIT = (id: number): string => `agent/player/deposit/${id}`;
// export const CREATE_PLAYER_PUT = (id: number): string => `agent/players/${id}`;

//Partner
export const CREATE_PARTNER_GET = (id: number): string => `agents/partner/info/${id}`;
export const CREATE_PARTNER_PUT = (id: number): string => `agent/partner/${id}`;
export const CREATE_CHANGE_PARTNER_PUT = (id: number): string => `agent/active-partner/${id}`;
export const CREATE_PARTNER_CURRENCIES_GET = (id: number | string): string => `agent/currencies/${id}`;

//Dashboard
export const CREATE_ACTIVITY_CARDS_GET = (code: string): string => `dashboard/overview/${code}`;
export const CREATE_ACTIVITY_CARDS_PERIODICAL_GET = (code: string): string => `dashboard/overview/periodical/${code}`;

//Access Template
export const CREATE_PERMISSION_TEMPLATES_PUT = (id: number | string): string => `agent/permission/templates/${id}`;
export const CREATE_PLAYER_DASHBOARD_GET = (id: number): string => `agent/player/dashboard/${id}`;
export const CREATE_PLAYER_BETS_GET = (id: string): string => `agent/bet/transactions/player/${id}`;
export const CREATE_PLAYER_TRANSACTIONS_GET = (id: string): string => `agent/player/transactions/${id}`;
export const CREATE_PLAYER_WALLET_HISTORY_GET = (id: string): string => `agent/payment/transactions/player/${id}`;
export const CREATE_AGENT_TRANSACTIONS_GET = (id: string): string => `agent/payment/transactions/agent/${id}`;
export const CREATE_AGENT_TRANSACTIONS_FROM_PARENT_GET = (id: string): string =>
  `agent/payment/transactions/fromparent/${id}`;
export const CREATE_AGENT_TRANSACTIONS_TO_SUB_AGENTS_GET = (id: string): string =>
  `agent/payment/transactions/tosubagents/${id}`;
export const CREATE_AGENT_TRANSACTIONS_TO_SUB_PLAYERS_GET = (id: string): string =>
  `agent/payment/transactions/tosubplayers/${id}`;
export const CREATE_AGENT_SUB_GET = (id: string): string => `agents/subs/${id}`;
export const PLAYER_DASHBOARD_GET = (id: number): string => `dashboard/player/${id}`;
export const AGENT_DASHBOARD_GET = (id: number): string => `dashboard/agent/${id}`;
