import translations from 'configs/translation.json';

export type TranslationKey = keyof typeof translations;
export type DynamicTranslationKey = {
  key: TranslationKey;
  replacementObject: Record<string, string | number>;
};

export type TranslationItem = TranslationKey | DynamicTranslationKey;

const constructDynamicKey = (key: string): string => `{{${key}}}`;

export const translate = (translationItem: TranslationItem): string => {
  if (typeof translationItem === 'string') return translationItem;

  const { key, replacementObject } = translationItem;

  let result: string = key;
  Object.entries(replacementObject).forEach(([dynamicKey, dynamicValue]) => {
    result = result.replace(constructDynamicKey(dynamicKey), dynamicValue.toString());
  });

  return result;
};
