//agent
export const LOGIN = 'agent/login';
export const LOGOUT = 'agent/logout';
export const PUT_AGENT_PASSWORD = 'agent/password';

export const GET_SEARCH = 'agent/search';
export const GET_CURRENCIES = 'agent/currency';
export const PUT_CURRENCY_ENABLE = 'agent/currencies/enable';
export const GET_BRANDING = 'agent/branding';

export const GET_PARTNERS = 'agent/partners';
export const GET_PARTNER = 'agents/partner/info/:partnerId';
export const POST_PARTNERS = 'agent/partner';
export const PUT_PARTNERS = 'agent/partner/:partnerId';
export const PUT_CHANGE_PARTNER = 'agent/active-partner/:partnerId';
export const GET_PARTNER_CURRENCIES = 'agent/currencies';
export const PUT_PARTNER_CURRENCY_AVAILABLE = 'agent/currencies/available';

export const GET_BET_TRANSACTIONS = 'agent/bet/transactions/player/:playerId';

export const GET_PLAYER_PAYMENT_TRANSACTIONS = 'agent/payment/transactions/player/:playerId';
export const GET_AGENT_PAYMENT_TRANSACTIONS = 'agent/payment/transactions/agent/:agentId';

export const GET_PERMISSION_TEMPLATES = 'agent/permission/templates';
export const GET_PERMISSION_TEMPLATES_SELECT = 'agent/permission/templates/select';
export const GET_GRANT_PERMISSION = 'agent/permissions/grant';
export const GET_ALL_PERMISSION = 'agent/permissions/all';
export const POST_PERMISSION_TEMPLATES = 'agent/permission/templates';
export const PUT_PERMISSION_TEMPLATES = 'agent/permission/templates/:templateId';

export const GET_OWN_PLAYERS = 'agent/players/own';
export const GET_SUB_PLAYERS = 'agent/players/sub';
export const POST_PLAYER = 'agent/player';
export const PUT_PLAYER = 'agent/players/:playerId';

export const GET_OWN_AGENTS = 'agents/own';
export const GET_SUB_AGENTS = 'agents/sub';
export const POST_AGENT = 'agents';
export const PUT_AGENT = 'agents/:id';

export const GET_OWN_WALLETS = 'agent/wallets';
export const GET_PLAYER_WALLETS = 'agent/player/wallets/:playerId';
export const GET_AGENT_WALLETS = 'agent/wallets/:agentId';
export const PUT_PLAYER_DEPOSIT = 'agent/player/deposit/:playerId';
export const PUT_PLAYER_WITHDRAW = 'agent/player/withdraw/:playerId';
export const PUT_AGENT_DEPOSIT = 'agent/deposit/:agentId';
export const PUT_AGENT_WITHDRAW = 'agent/withdraw/:agentId';
export const POST_PLAYER_WALLET = 'agent/wallets/player/:playerId';
export const POST_AGENT_WALLET = '/agent/wallets/agent/:agentId';

//old
export const PARTNER_AGGREGATORS_GET = 'system/aggregators/partner';
export const SYSTEM_CATEGORIES_GET = 'system/categories';
export const PARTNER_GAMES_GET = 'system/games/partner';
export const PARTNER_PROVIDERS_GET = 'system/providers/partner';
export const PARTNER_PAYMENT_AGGREGATORS_GET = 'payment/partner/aggregators';
export const PARTNER_PAYMENT_CATEGORIES_GET = 'payment/website/categories/partner';
export const PARTNER_PAYMENT_PROVIDERS_GET = 'payment/website/providers/partner';
export const WEBSITES_GET = 'websites';
export const LANGUAGES_GET = 'language';
export const PLAYERS_GROUP_POST = 'riskGroup';
export const WEBSITE_TOURNAMENT_TEMPLATES_GET = 'tournament-templates';
export const PRODUCTS_CASINO_LIMITS_GET = 'products/casino/limits';
export const CASINO_LIMITS_POST = 'casino/limits';
export const WEBSITE_SPORTS_GET = 'website/sport';
export const SYSTEM_PAYMENT_AGGREGATORS_NAMES_GET = 'payment/system/aggregator/names';
export const SYSTEM_PAYMENT_PROVIDER_NAMES_GET = 'payment/system/provider/names';
export const WEBSITE_CREATE = 'website';
export const PLAYERS_LIST_GET = 'players';
export const ALL_COUTRIES_GET = 'countries/all';
export const GET_WEBSITE_TOURNAMENT_TEMPLATES = 'tournament/translations';

export const GET_REPORTS_BY_PRODUCTS = 'agent/report/product';
export const GET_REPORTS_BY_PROVIDER = 'agent/report/provider';
export const GET_REPORTS_BY_PLAYER = 'agent/report/player';
export const GET_REPORTS_BY_AGENT = 'agent/report/agent';
