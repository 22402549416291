import { configureStore } from '@reduxjs/toolkit';

import * as reducer from './reducers';

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
    }),
});

export { store };
