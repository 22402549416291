import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GET_OWN_WALLETS, LOGIN, PUT_CHANGE_PARTNER, GET_BRANDING } from 'api/paths/constants';
import { CREATE_CHANGE_PARTNER_PUT } from 'api/paths/creators';
import { RootState } from 'store/types';
import { authStorage } from 'store/storage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import fetcher from 'utils/fetcher';
import { ApiResponseType, WalletType } from 'types';
import { EMPTY_OBJECT } from 'constant';

import {
  AuthLoginResponseType,
  AuthLoginSchemaType,
  UseAuthStateType,
  UseAuthActionsType,
  IAgent,
  AuthState,
  AccountWalletsResponseType,
  BrandingType,
} from './types';
import { LOGOUT_ACTION_TYPE } from './actionTypes';

const initialState: UseAuthStateType = {
  isLoading: false,
  branding: null,
  data: authStorage.get(),
};

const getBranding = createAsyncThunk<ApiResponseType<BrandingType>>(GET_BRANDING, async (body, { rejectWithValue }) => {
  try {
    const response = await fetcher<BrandingType>({
      method: 'GET',
      url: GET_BRANDING,
    });
    return response;
  } catch (error) {
    return rejectWithValue(error);
  }
});

const getAccountWallets = createAsyncThunk<ApiResponseType<AccountWalletsResponseType>>(
  GET_OWN_WALLETS,
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<AccountWalletsResponseType>({
        method: 'GET',
        url: GET_OWN_WALLETS,
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const login = createAsyncThunk<AuthLoginResponseType, AuthLoginSchemaType>(
  'agent/login',
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<
        {
          accessToken: string;
          agent: IAgent;
        },
        AuthLoginSchemaType
      >({
        url: LOGIN,
        method: 'POST',
        body,
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const changePartner = createAsyncThunk<AuthLoginResponseType, number>(
  PUT_CHANGE_PARTNER,
  async (body, { rejectWithValue }) => {
    try {
      const response = await fetcher<
        {
          accessToken: string;
          agent: IAgent;
        },
        AuthLoginSchemaType
      >({
        url: CREATE_CHANGE_PARTNER_PUT(body),
        method: 'PUT',
      });

      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: EMPTY_OBJECT,
  extraReducers: (builder) =>
    builder
      .addCase(login.pending.type, (state): void => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled.type, (state, action: PayloadAction<{ data: AuthState }>): void => {
        if (action.payload?.data?.agent?.id) {
          authStorage.set(action.payload.data);
          state.data = action.payload?.data;
        }
        state.isLoading = false;
      })
      .addCase(login.rejected.type, (state, action: PayloadAction<ApiResponseType<null>>): void => {
        state.data = EMPTY_OBJECT;
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(changePartner.fulfilled.type, (state, action: PayloadAction<{ data: AuthState }>): void => {
        if (action.payload?.data?.agent?.id) {
          authStorage.set(action.payload.data);
          state.data = action.payload?.data;
        }
      })
      .addCase(getAccountWallets.fulfilled.type, (state, action: PayloadAction<{ data: WalletType[] }>): void => {
        if (state.data) {
          state.data.wallets = action.payload.data;
        }
      })
      .addCase(getBranding.fulfilled.type, (state, action: PayloadAction<{ data: BrandingType }>): void => {
        if (state.data) {
          state.branding = action.payload.data;
          const lStorage = authStorage.get();
          authStorage.set({ ...lStorage, branding: action.payload.data });
        }
      }),
});

const useAuth = (): [UseAuthStateType, UseAuthActionsType] => {
  const state = useAppSelector((store: RootState) => store.auth);
  const dispatch = useAppDispatch();

  const actions = {
    login: async (params: AuthLoginSchemaType): Promise<void> => {
      await dispatch(login(params));
    },
    changePartner: async (params: number): Promise<void> => {
      await dispatch(changePartner(params));
    },
    logout: (): void => {
      authStorage.clear();
      dispatch({ type: LOGOUT_ACTION_TYPE });
    },
    addWallets: async (): Promise<void> => {
      await dispatch(getAccountWallets());
    },
    getBranding: async (): Promise<void> => {
      await dispatch(getBranding());
    },
  };

  return [state, actions];
};

export { authSlice };

export * from './types';

export default useAuth;
