import { useMemo, useState, useCallback, ReactElement } from 'react';
import { Control, FieldValues, Path, Controller } from 'react-hook-form';
import {
  Box,
  SxProps,
  TextFieldProps,
  Theme,
  Typography,
  Autocomplete as BaseAutocomplete,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import fetcher from 'utils/fetcher';
import { translate, TranslationKey } from 'utils/translate';
import { useDebounce } from 'hooks/useDebounce';

interface FormAutocompleteProps<T extends FieldValues> {
  label?: TranslationKey;
  control?: Control<T>;
  placeholder?: TranslationKey;
  options?: T[];
  optionLabel?: string;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  multiple?: boolean;
  isAsync?: boolean;
  pathName?: string;
  pageSize?: string;
  page?: string;
  searchTerm?: string;
  onChange?: (value: T | T[] | null) => void;
}

const sx = {
  label: ({ disabled }: { disabled: boolean }): SxProps<Theme> => ({
    mb: 0.5,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: disabled ? 'custom.grayscale.scale.50' : undefined,
  }),
};

const Autocomplete = <T extends FieldValues>({
  name,
  control,
  required = false,
  disabled = false,
  fullWidth,
  label,
  placeholder,
  multiple,
  helperText,
  isAsync = false,
  disableClearable = true,
  disableCloseOnSelect = false,
  options = [],
  optionLabel = 'name',
  pathName = '',
  pageSize = '50',
  page = '1',
  searchTerm = '',
  onChange,
}: TextFieldProps & FormAutocompleteProps<T>): JSX.Element | null => {
  const fieldPlaceholder = useMemo(
    () => (placeholder && translate(placeholder)) || (label ? `${translate('Type')} ${translate(label)}` : ''),
    [placeholder, label],
  );

  const [loadOptions, setLoadOptions] = useState<readonly T[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(isAsync);

  const handleSearchChange = useCallback(
    (newValue: string): void => {
      if (!isAsync) {
        return;
      }

      (async (): Promise<void> => {
        try {
          setIsLoading(true);
          const response = await fetcher<T, Partial<T>>({
            url: pathName,
            params: {
              page,
              size: pageSize,
              [searchTerm]: newValue,
            },
          });

          setLoadOptions((response?.data?.data as unknown as T[]) ?? []);
        } catch {
          setLoadOptions([]);
        } finally {
          setIsLoading(false);
        }
      })();
    },
    [pathName, pageSize, page, searchTerm, isAsync],
  );
  const debouncedHandleSearchChanges = useDebounce(handleSearchChange, 700);
  const debouncedResetHandleSearchChanges = useDebounce(handleSearchChange, 100);

  if (!name || !control) {
    return null;
  }

  return (
    <Box width={fullWidth ? '100%' : 'auto'}>
      <Typography sx={sx.label({ disabled })}>
        {label && translate(label)} {required && '*'}
      </Typography>
      <Controller
        name={name as Path<T>}
        control={control}
        rules={{ required }}
        render={({ field, fieldState }): ReactElement => (
          <BaseAutocomplete
            {...field}
            disabled={disabled}
            multiple={multiple}
            disableCloseOnSelect={disableCloseOnSelect}
            disableClearable={disableClearable}
            options={isAsync ? loadOptions : options}
            value={field.value || null}
            loading={isLoading}
            inputValue={inputValue}
            onInputChange={(event, newInputValue): void => {
              setInputValue(newInputValue);
              debouncedHandleSearchChanges(newInputValue);
            }}
            onChange={(_, data): void => {
              field.onChange(data);
              onChange?.(data);
            }}
            onOpen={(): void => {
              debouncedResetHandleSearchChanges('');
            }}
            onClose={(): void => {
              setLoadOptions([]);
            }}
            filterOptions={isAsync ? (x): T[] => x : undefined}
            isOptionEqualToValue={(option: T, value: T): boolean => option?.id === value?.id}
            getOptionLabel={(option): string => option[optionLabel] ?? ''}
            popupIcon={<ExpandMoreIcon />}
            renderInput={(params): ReactElement => (
              <TextField
                {...params}
                fullWidth
                required={required}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || helperText}
                placeholder={fieldPlaceholder}
                sx={{
                  '& .MuiInputBase-root, & .MuiInputBase-input': {
                    paddingTop: '0 !important',
                    paddingBottom: '0 !important',
                  },
                }}
              />
            )}
          />
        )}
      />
    </Box>
  );
};

export default Autocomplete;
