import { authSlice } from './auth';
import { partnersSlice } from './partner';
import { playerSlice } from './player';
import { agentSlice } from './agent';
import { allCountriesSlice } from './countries';
export const partners = partnersSlice.reducer;
export const auth = authSlice.reducer;
export const player = playerSlice.reducer;
export const agent = agentSlice.reducer;
export const allCountries = allCountriesSlice.reducer;
