import { AgentPermissionsEnum } from 'enums/accessTemplates';

import { OptionType } from 'types';
import { PERMISSIONS_OPTIONS } from 'configs';

export const generatePermissionList = (existPermissions: string[]): OptionType[] => {
  if (
    existPermissions.includes(AgentPermissionsEnum.SUPER_ADMIN) ||
    existPermissions.includes(AgentPermissionsEnum.ADMIN)
  ) {
    return PERMISSIONS_OPTIONS.filter((i) => i.id !== AgentPermissionsEnum.SUPER_ADMIN);
  } else {
    return PERMISSIONS_OPTIONS.filter((i) => existPermissions.includes(i.id));
  }
};

export const generateDefaultValue = (
  existPermissions: string[],
  selectedPermission: (boolean | AgentPermissionsEnum | undefined)[],
): boolean[] => {
  const options = generatePermissionList(existPermissions);
  const permissions: boolean[] = [];
  options.forEach((i) => {
    permissions.push(selectedPermission.includes(i.id as AgentPermissionsEnum));
  });

  return permissions;
};

export const AccessChangePartnerPermissions = [AgentPermissionsEnum.SUPER_ADMIN, AgentPermissionsEnum.ADMIN];

export const AccessCreateTemplatePermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.ACCESS_TEMPLATE_WRITE,
];

export const AccessCreatePermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.AGENT_OWN_WRITE,
  AgentPermissionsEnum.PLAYER_OWN_WRITE,
];

export const AccessCreateAgentPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.AGENT_OWN_WRITE,
];

export const AccessEditOwnAgentPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.AGENT_OWN_WRITE,
];

export const AccessEditSubAgentPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.AGENT_SUB_WRITE,
];

export const AccessCreatePlayerPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.PLAYER_OWN_WRITE,
];

export const AccessSubPlayerPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.PLAYER_SUB_READ,
  AgentPermissionsEnum.PLAYER_SUB_WRITE,
];

export const AccessOwnPlayerPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.PLAYER_OWN_READ,
  AgentPermissionsEnum.PLAYER_OWN_WRITE,
];

export const AccessEditOwnPlayerPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.PLAYER_OWN_WRITE,
];

export const AccessEditSubPlayerPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.PLAYER_SUB_WRITE,
];

export const AccessAgentsInfoPermissions = [
  AgentPermissionsEnum.SUPER_ADMIN,
  AgentPermissionsEnum.ADMIN,
  AgentPermissionsEnum.AGENT_OWN_READ,
  AgentPermissionsEnum.AGENT_OWN_WRITE,
];
