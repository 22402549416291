import { FC } from 'react';

import Redirect from 'components/common/Redirect';
import useAuth from 'store/auth';
import { AccessOwnPlayerPermissions } from 'utils/accessTemplates';

const PlayerCheckPermission: FC = () => {
  const [authState] = useAuth();
  const authAgent = authState?.data?.agent;

  if (!AccessOwnPlayerPermissions.some((r) => authAgent?.permissions.includes(r))) {
    return <Redirect to="sub" />;
  }

  return <Redirect to="own" />;
};

export default PlayerCheckPermission;
