import { ReactNode, useMemo } from 'react';
import {
  Box,
  Select as BaseSelect,
  Checkbox,
  MenuItem,
  Typography,
  SelectProps as BaseSelectProps,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';

import { TranslationKey, translate } from 'utils/translate';
import { EMPTY_ARRAY } from 'constant';
import { OptionType } from 'types/utils';

export interface SelectProps {
  label?: TranslationKey;
  multiple?: boolean;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  value: OptionType['id'] | OptionType['id'][];
  placeholder?: string;
  options?: OptionType[];
  autoPrefilled?: boolean;
}

const Select = ({
  label,
  value,
  error,
  sx: _sx,
  multiple,
  disabled,
  required,
  onChange,
  placeholder,
  autoPrefilled,
  options = EMPTY_ARRAY,
  size = 'small',
}: Omit<BaseSelectProps, 'error'> & SelectProps): JSX.Element => {
  const isValidOptions = useMemo(
    () => Array.isArray(options) && options?.length > (autoPrefilled ? 1 : 0),
    [autoPrefilled, options],
  );

  const selectedValue = useMemo(
    () =>
      isValidOptions && value && Array.isArray(value)
        ? multiple
          ? value
          : EMPTY_ARRAY
        : multiple
          ? EMPTY_ARRAY
          : value || '',
    [isValidOptions, multiple, value],
  );

  const getName = (currentValue: OptionType['id'] | OptionType['id'][]): OptionType['name'] | undefined => {
    if (isValidOptions) {
      if (Array.isArray(currentValue)) {
        return currentValue.map(getName).join(', ');
      }

      return options.find((item) => item.id === currentValue)?.name;
    }
    return;
  };

  const renderValue = (currentValue: OptionType['id'] | OptionType['id'][]): JSX.Element => {
    const isValidValue = multiple ? Array.isArray(currentValue) && currentValue.length : currentValue;

    if (isValidValue) {
      return (
        <Typography noWrap lineHeight={'26px'} fontSize={14} color="custom.neutrals.scale.500">
          {getName(currentValue)}
        </Typography>
      );
    }

    return (
      <Typography noWrap lineHeight={'26px'} fontSize={14} color="custom.neutrals.scale.300">
        {placeholder || 'Select'}
      </Typography>
    );
  };

  const isFieldsDisabled = disabled || !isValidOptions;

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode): void => {
    onChange?.(event, child);
  };

  return (
    <Box width="100%" sx={_sx}>
      {label && (
        <Typography variant="body2" fontWeight={500} mb={0.5} color="custom.neutrals.scale.500">
          {label && translate(label)} {required && '*'}
        </Typography>
      )}
      <BaseSelect
        fullWidth
        size={size}
        displayEmpty
        error={!!error}
        multiple={multiple}
        value={selectedValue}
        renderValue={renderValue}
        disabled={isFieldsDisabled}
        onChange={handleChange}
      >
        {isValidOptions &&
          options.map((option) => {
            const indeterminate = false;
            const selected = multiple && Array.isArray(value) ? value.includes(option.id) : value === option.id;

            return (
              <MenuItem
                key={option.id}
                value={option.id}
                sx={{
                  ...(multiple && {
                    py: 0,
                    '&.Mui-selected': {
                      bgcolor: 'transparent',
                    },
                  }),
                }}
              >
                {multiple && <Checkbox checked={selected} indeterminate={indeterminate} />}
                {option.name}
              </MenuItem>
            );
          })}
      </BaseSelect>
      {!!error && (
        <FormHelperText error sx={{ mx: 1.75 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default Select;
