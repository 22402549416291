import { createTheme } from '@mui/material/styles';
import { Theme } from '@emotion/react';

import Rectangle from 'components/common/icons/Rectangle';
import ArrowDownIcon from 'components/common/icons/ArrowDown';
import ClearRectangle from 'components/common/icons/ClearRectangle';
import CheckRectangle from 'components/common/icons/CheckRectangle';

import { shadows } from './shadows';
import * as customPalette from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    custom: typeof customPalette;
  }
  interface PaletteOptions {
    custom?: typeof customPalette;
  }
}

const shape = {
  borderRadius: 8,
};

const typography = {
  fontFamily: 'Poppins, sans-serif',
  h4: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '32px',
    letterSpacing: '-0.48px',
    color: customPalette.neutrals.scale[600],
  },
  subtitle1: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '28px',
    letterSpacing: '-0.4x',
  },
  subtitle2: {
    fontSize: 18,
    fontWeight: 400,
    lineHeight: '26px',
  },
  body1: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '-0.146x',
  },
  body2: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '-0.14px',
  },
  caption: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '-0.24px',
  },
  overline: {
    fontSize: 11,
    fontWeight: 400,
    lineHeight: '14px',
    letterSpacing: '-0.22px',
  },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 834,
    lg: 1200,
    xl: 1440,
  },
};

const useCustomTheme = (): Theme => {
  const theme = createTheme({
    shape,
    shadows,
    typography,
    breakpoints,
    palette: {
      custom: customPalette,
      // text: {
      //     primary: customPalette.grayscale.scale[100],
      // },
      // common: {
      //     black: customPalette.common.black,
      //     white: customPalette.common.white,
      // },
      primary: {
        main: customPalette.primary.scale[500],
        dark: customPalette.primary.scale[700],
      },
      // secondary: {
      //     main: customPalette.secondaryColor.main,
      //     dark: customPalette.secondaryColor.hover,
      // },
      // error: {
      //     main: customPalette.tints.red.main,
      // },
      // success: {
      //     main: customPalette.tints.green.main,
      // },
      // warning: {
      //     main: customPalette.tints.amber.main,
      // },
      // background: {
      //     paper: '#FFFFFF',
      //     default: '#FBFBFC',
      // },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            '*::-webkit-scrollbar': {
              width: '5px',
              height: '5px',
            },
            '*::-webkit-scrollbar-track': {
              backgroundColor: customPalette.neutrals.scale[25],
            },
            '*::-webkit-scrollbar-thumb': {
              background: customPalette.neutrals.scale[400],
              borderRadius: '2px',
            },
            '*::-webkit-scrollbar-thumb:hover': {
              background: customPalette.primary.scale[600],
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            '.MuiBackdrop-root': {
              backdropFilter: 'blur(5px)',
            },
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          IconComponent: (props) => <ArrowDownIcon {...props} />,
        },
        styleOverrides: {
          icon: {
            transition: '0.1s',
            color: customPalette.neutrals.scale[800],
          },
        },
      },
      MuiInputBase: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            borderRadius: 6,
            background: customPalette.common.white,
            '&[class$="MuiSelect-root"]': {
              height: 48,
            },
            '& input': {
              paddingTop: 0,
              paddingBottom: 0,
              height: 48,
            },
            '&.MuiInputBase-multiline': {
              paddingTop: 12,
              paddingBottom: 12,
            },
            '& input, & textarea': {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '24px',
              color: customPalette.neutrals.scale[700],
            },
            '& fieldset': {
              top: 0,
              borderRadius: 6,
              boxShadow: shadows[1],
              borderColor: customPalette.neutrals.scale[100],
              '& > legend': {
                lineHeight: 0,
              },
            },
            '&.Mui-error': {
              '& fieldset': {
                // borderColor: `${customPalette.tints.red.main} !important`,
              },
            },
            '&.Mui-focused, &.MuiOutlinedInput-root.Mui-focused': {
              '& fieldset': {
                borderWidth: 1,
                borderColor: customPalette.primary.scale[300],
                outline: `4px solid ${customPalette.primary.scale[50]}`,
              },
            },
            '& .MuiSvgIcon-root': {
              '&.MuiSvgIcon-fontSizeSmall': {
                fontSize: 16,
              },
            },
            '&.Mui-disabled': {
              // backgroundColor: customPalette.grayscale.scale[10],
              '& fieldset': {
                // borderColor: `${customPalette.grayscale.scale[20]} !important`,
              },
              '& input': {
                // color: customPalette.grayscale.scale[50],
              },
            },
            '& .MuiSelect-select': {
              '&.Mui-disabled p': {
                // color: `${customPalette.grayscale.scale[50]} !important`,
              },
            },
            '& .MuiIconButton-root svg': {
              fontSize: 20,
              color: customPalette.neutrals.scale[400],
            },
          },
          sizeSmall: {
            '&[class$="MuiSelect-root"]': {
              height: 36,
            },
            '& input': {
              height: 36,
              paddingLeft: 12,
              paddingRight: 12,
            },
            '& input, & textarea': {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: '18px',
            },
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            textTransform: 'initial',
            borderRadius: shape.borderRadius,
            '&:hover': {
              boxShadow: shadows[1],
            },
            '&:active': {
              boxShadow: shadows[1],
            },
            // '&.Mui-focusVisible': {
            //     boxShadow: 'none',
            //     outline: `2px solid ${customPalette.additional.violet[20]}`,
            // },
          },
          sizeSmall: {
            height: 36,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
            borderRadius: shape.borderRadius,
          },
          sizeMedium: {
            height: 40,
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '20px',
            borderRadius: shape.borderRadius,
          },
          sizeLarge: {
            height: 44,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: '24px',
            borderRadius: shape.borderRadius,
          },
          outlinedInherit: {
            background: customPalette.common.white,
            color: customPalette.neutrals.scale[400],
            borderColor: customPalette.neutrals.scale[100],
          },
          contained: {
            boxShadow: 'none',
          },
          containedError: {
            color: customPalette.red.scale[500],
            background: customPalette.red.scale[50],
            '&:hover': {
              color: customPalette.red.scale[600],
              background: customPalette.red.scale[200],
            },
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontSize: 16,
            fontWeight: 500,
            border: '2px solid white',
            color: customPalette.primary.scale[500],
            backgroundColor: customPalette.primary.scale[50],
            boxShadow: shadows[1],
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            border: `1px solid ${customPalette.neutrals.scale[100]}`,
          },
          list: {
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            ...typography.body1,
            fontWeight: 500,
            paddingTop: 12,
            paddingBottom: 12,
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            'not(.mTable)': {
              'tr:last-child td': {
                borderBottom: 'none !important',
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            '&.MuiTableCell-head': {
              ...typography.caption,
              fontWeight: 600,
              textTransform: 'uppercase',
              color: customPalette.neutrals.scale[400],
              background: customPalette.neutrals.scale[70],
              '&.MuiTableCell-sizeMedium': {
                padding: '12px 16px',
              },
            },
            '&.MuiTableCell-sizeSmall': {
              padding: '8px',
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontSize: 12,
            fontWeight: 500,
          },
          colorSuccess: {
            color: customPalette.green.scale[700],
            background: customPalette.green.scale[100],
          },
          colorError: {
            color: customPalette.red.scale[700],
            background: customPalette.red.scale[100],
          },
          colorPrimary: {
            color: customPalette.primary.scale[700],
            background: customPalette.primary.scale[100],
          },
          colorWarning: {
            color: customPalette.yellow.scale[700],
            background: customPalette.yellow.scale[100],
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          filledSuccess: {
            color: customPalette.green.scale[700],
            background: customPalette.green.scale[100],
          },
          filledError: {
            color: customPalette.red.scale[700],
            background: customPalette.red.scale[100],
          },
          filledInfo: {
            color: customPalette.primary.scale[700],
            background: customPalette.primary.scale[100],
          },
          filledWarning: {
            color: customPalette.yellow.scale[700],
            background: customPalette.yellow.scale[100],
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: 'auto',
          },
          flexContainer: {
            gap: '16px',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            padding: '12px 4px',
            minWidth: 'auto',
            minHeight: 'auto',
            textTransform: 'inherit',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 12,
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: <Rectangle />,
          checkedIcon: <CheckRectangle />,
          indeterminateIcon: <ClearRectangle />,
        },
        styleOverrides: {
          root: {
            '&:not(.Mui-checked) svg': {
              color: customPalette.neutrals.scale[300],
            },
            '&.MuiCheckbox-indeterminate svg': {
              color: customPalette.primary.scale[300],
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '&.MuiMenu-list': {
              maxHeight: 300,
            },
          },
        },
      },
    },
  });

  return theme;
};

export default useCustomTheme;
