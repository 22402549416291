import { useState } from 'react';

type Timer = ReturnType<typeof setTimeout>;

export const useDebounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  delay = 100,
): ((...args: Parameters<F>) => void) => {
  const [timer, setTimer] = useState<Timer>();
  return (...args: Parameters<F>): void => {
    const newTimer = setTimeout(() => {
      func(...args);
    }, delay);
    clearTimeout(timer);
    setTimer(newTimer);
  };
};
