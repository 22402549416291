import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FC } from 'react';

const NotificationIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon width={24} height={24} viewBox="0 0 22 22" {...props}>
    <path
      opacity="0.4"
      d="M17.2998 13.5404L17.3011 13.5426C17.5789 13.9984 17.6274 14.5341 17.4436 15.0219C17.2569 15.5173 16.8737 15.8903 16.3804 16.0524L16.3804 16.0523L16.3776 16.0533C14.6523 16.6313 12.8361 16.9165 11.0181 16.9165C9.20013 16.9165 7.38474 16.6313 5.66082 16.0448L5.65982 16.0445C5.10443 15.8569 4.71086 15.4796 4.54966 15.0296L4.54968 15.0296L4.54824 15.0257C4.37704 14.56 4.42991 14.0391 4.72758 13.5398C4.72781 13.5394 4.72803 13.5391 4.72826 13.5387L5.65262 12.0103L5.65268 12.0103L5.65667 12.0035C5.7836 11.7859 5.88924 11.4967 5.96279 11.2262C6.03625 10.956 6.09145 10.6539 6.09145 10.404V8.08487C6.09145 5.37213 8.29731 3.1582 11.0181 3.1582C13.7389 3.1582 15.9448 5.37213 15.9448 8.08487V10.404C15.9448 10.6539 16 10.9559 16.0733 11.2267C16.1469 11.4987 16.2519 11.7879 16.3766 12.0075L16.3765 12.0076L16.3832 12.0187L17.2998 13.5404Z"
      fill="inherit"
      stroke="inherit"
    />
    <path
      d="M13.0626 3.0435C12.4301 2.796 11.7426 2.6585 11.0184 2.6585C10.3034 2.6585 9.6159 2.78683 8.9834 3.0435C9.37757 2.301 10.1567 1.8335 11.0184 1.8335C11.8892 1.8335 12.6592 2.301 13.0626 3.0435Z"
      fill="inherit"
    />
    <path
      d="M13.5942 18.3427C13.2092 19.406 12.1917 20.1668 11 20.1668C10.2759 20.1668 9.56087 19.8735 9.05671 19.351C8.76337 19.076 8.54337 18.7093 8.41504 18.3335C8.53421 18.3518 8.65337 18.361 8.78171 18.3793C8.99254 18.4068 9.21254 18.4343 9.43254 18.4527C9.95504 18.4985 10.4867 18.526 11.0184 18.526C11.5409 18.526 12.0634 18.4985 12.5767 18.4527C12.7692 18.4343 12.9617 18.4252 13.145 18.3977C13.2917 18.3793 13.4384 18.361 13.5942 18.3427Z"
      fill="inherit"
    />
  </SvgIcon>
);

export default NotificationIcon;
